import React from 'react'
import { Grid, TextField, Button, Paper, MenuItem } from '@material-ui/core'
import { Form, withFormik } from 'formik'
import { theme } from '../../../assets/styles'
import { formStyle } from './style'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/styles'
import { LIST_ITEMS_QUERY } from 'components/Dashboard/components/MaterialMaster/queries/queries'
import { Query } from '@apollo/react-components'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.description, query)
  const parts = parse(suggestion.description, matches)

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  )
}

const AddItemForm = ({
  values,
  handleChange,
  errors,
  touched,
  setFieldValue,
}) => {
  const classes = formStyle()

  const [tmpItem, setTmpItem] = React.useState('')

  const handleSuggestionsFetchRequested = ({ value }) => {
    setTmpItem(value)
  }

  const getSuggestionValue = suggestion => {
    values.id = suggestion.id
    values.data = suggestion
    values.item = suggestion.description
    return suggestion.description
  }

  const handleSuggestionsClearRequested = () => {}

  const autosuggestProps = {
    renderInputComponent,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  }

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <Query
                query={LIST_ITEMS_QUERY}
                variables={{ number: 1, size: 20, filter: tmpItem }}
              >
                {({ data: { listItems } }) => {
                  return (
                    <Autosuggest
                      suggestions={listItems ? listItems.data : []}
                      {...autosuggestProps}
                      inputProps={{
                        classes,
                        error: errors.item && touched.item,
                        helperText: touched.item && errors.item,
                        id: 'react-autosuggest-simple',
                        label: 'Items',

                        placeholder: 'Search Items',
                        name: 'item',
                        value: values.item,
                        onChange: (e, { newValue }) => {
                          setFieldValue('item', newValue)
                        },
                      }}
                      theme={{
                        container: classes.container,
                        suggestionsContainerOpen:
                          classes.suggestionsContainerOpen,
                        suggestionsList: classes.suggestionsList,
                        suggestion: classes.suggestion,
                      }}
                      renderSuggestionsContainer={options => (
                        <Paper {...options.containerProps}>
                          {options.children}
                        </Paper>
                      )}
                    />
                  )
                }}
              </Query>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <TextField
                name="quantity"
                type="number"
                label="Quantity"
                margin="normal"
                fullWidth
                placeholder="0"
                className={classes.textField}
                onChange={handleChange}
                value={values.quantity}
                error={errors.quantity && touched.quantity}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.addButton}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Form>
      </ThemeProvider>
    </div>
  )
}

AddItemForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
}

const AddItemFormik = withFormik({
  mapPropsToValues() {
    return {
      item: '',
      quantity: 0,
    }
  },
  validationSchema: Yup.object().shape({
    item: Yup.string().required('Please specify the item'),
    quantity: Yup.number()
      .positive('Must be greater than 0')
      .required('Please specify the quantity'),
  }),
  enableReinitialize: true,

  handleSubmit(variables, { props, resetForm }) {
    props.setItems(variables)
    resetForm()
  },
})(AddItemForm)

export default AddItemFormik
