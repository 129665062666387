import React from 'react'
import TablePaginationActions from '../../../MaterialMaster/common/TablePaginationActions/TablePaginationActions'
import { TablePagination } from '@material-ui/core'
import PropTypes from 'prop-types'

export default function TableFooterReport(props) {
  return (
    <TablePagination
      component="div"
      align="right"
      style={{
        alignContent: 'right',
        alignItems: 'right',
        alignSelf: 'right',
        textAlign: 'right',
      }}
      rowsPerPageOptions={[5, 10, 20]}
      count={parseInt(props.total)}
      rowsPerPage={parseInt(props.perPage)}
      page={props.page - 1}
      onChangePage={props.handleChangePage}
      onChangeRowsPerPage={props.handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  )
}

TableFooterReport.propTypes = {
  total: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
}
