import { makeStyles, createMuiTheme } from '@material-ui/core'

const defaultTheme = createMuiTheme()

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2d3e50',
    },
  },

  overrides: {
    MuiTablePagination: {
      caption: {
        [defaultTheme.breakpoints.down('xs')]: {
          display: 'none',
        },
      },
      selectRoot: {
        [defaultTheme.breakpoints.down('xs')]: {
          display: 'none',
          paddingLeft: 0,
          paddingRight: 20,
        },
      },
      toolbar: {
        [defaultTheme.breakpoints.down('xs')]: {
          paddingLeft: 0,
          paddingRight: 20,
        },
      },
    },
  },
})

export const mainPageStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))
