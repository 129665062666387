import React from 'react'
import AccountsTable from './Table'
import { Container, Grid, Paper } from '@material-ui/core'
import { materialMasterStyles } from '../assets/styles'
import ToolBar from './Toolbar'
import AccountsCreate from './Actions/CreateAccounts/Dialog'

export default function AccountsPage() {
  const classes = materialMasterStyles()

  let [selectedDate, handleDateChange] = React.useState(new Date())
  let [searchString, setSearch] = React.useState('')
  let [dialog, setDialog] = React.useState({
    open: false,
    type: 'CREATE',
    data: null,
  })

  return (
    <>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={2}>
          {/* TOOLBAR */}
          <Grid item xs={12} md={12} lg={12}>
            <ToolBar
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
              setSearch={setSearch}
              setDialog={setDialog}
              dialog={dialog}
              title="Accounts Table"
            />
          </Grid>
          {/* Purchase Order Table*/}
          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <AccountsTable
                dialog={dialog}
                date={selectedDate}
                search={searchString}
                setDialog={setDialog}
              />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <AccountsCreate
        dialog={dialog}
        setDialog={setDialog}
        user={dialog.data}
      />
    </>
  )
}
