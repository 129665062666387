import { fade, makeStyles, createMuiTheme } from '@material-ui/core/styles'

// Styles for ToolBar

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2d3e50',
    },
  },
})

export const toolBarStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      minWidth: 150,
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: 200,
      '&:focus': {
        width: 200,
      },
    },
  },
  addItemBtn: {
    marginLeft: '10px',
    marginRight: '0px',
    color: '#FFFFFF',
    // borderColor: '#FFFFFF',
    backgroundColor: '#2d3e50',
    border: '1px solid #2d3e50',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: 50,
    },
    transition: '200ms ease-in-out',
    '&:hover': {
      border: '1px solid white',
    },
  },
}))
