import { GET_FLASH_MESSAGE } from './queries'

export const ConstantCreator = actionStr => {
  const initialize = actionStr
  const success = `${actionStr}_SUCCESS`
  const fail = `${actionStr}_FAIL`
  return {
    INIT: initialize,
    SUCCESS: success,
    FAIL: fail,
  }
}

export const ShowFlashMessage = (cache, message, variant) => {
  let data = {
    flashMessage: {
      __typename: 'FlashMessage',
      open: true,
      message,
      variant: variant || 'success',
    },
  }

  cache.writeQuery({ query: GET_FLASH_MESSAGE, data })
}
