import React from 'react'
import {
  CREATE_USER_MUTATION,
  UPDATE_USER_MUTATION,
  CHANGE_USER_PASSWORD_MUTATION,
} from '../../../mutation'
import { Mutation } from '@apollo/react-components'
import AccountsAddWithFormik from './Forms/AddAccountsForm'
import ChangePasswordWithFormik from './Forms/ChangePasswordForm'
import CreateModal from '../../../assets/customModal'
import { ShowFlashMessage } from 'utils'
import PropTypes from 'prop-types'

export default function AccountsCreate({ dialog, setDialog, user }) {
  return (
    <React.Fragment>
      {dialog.type === 'EDIT' && (
        <Mutation
          mutation={UPDATE_USER_MUTATION}
          refetchQueries={['ListUsers']}
          update={(cache, { data: { updateUser } }) => {
            ShowFlashMessage(
              cache,
              `User with ID # ${updateUser.id} successfuly added`
            )
          }}
          onCompleted={() => {
            //Close Dialog
            setDialog({ ...dialog, open: false, type: 'EDIT' })
          }}
        >
          {mutate => {
            return (
              <CreateModal
                title="Edit Account"
                closeDialog={() => setDialog({ ...dialog, open: false })}
                open={dialog.open}
                FormContent={
                  <AccountsAddWithFormik
                    onSubmit={mutate}
                    user={user}
                    type={dialog.type}
                  />
                }
              />
            )
          }}
        </Mutation>
      )}
      {dialog.type === 'CREATE' && (
        <Mutation
          mutation={CREATE_USER_MUTATION}
          refetchQueries={['ListUsers']}
          update={(cache, { data: { createUser } }) => {
            ShowFlashMessage(
              cache,
              `User with ID # ${createUser.id} successfuly added`
            )
          }}
          onCompleted={() => {
            //Close Dialog
            setDialog({ ...dialog, open: false, type: 'CREATE' })
          }}
        >
          {mutate => {
            return (
              <CreateModal
                title="Add Account"
                closeDialog={() => setDialog({ ...dialog, open: false })}
                open={dialog.open}
                FormContent={
                  <AccountsAddWithFormik onSubmit={mutate} type={dialog.type} />
                }
              />
            )
          }}
        </Mutation>
      )}

      {dialog.type === 'PASSWORD' && (
        <Mutation
          mutation={CHANGE_USER_PASSWORD_MUTATION}
          refetchQueries={['ListUsers']}
          update={(cache, { data: { changeUserPassword } }) => {
            ShowFlashMessage(
              cache,
              `User with ID # ${
                changeUserPassword.id
              } changed password successfully`
            )
          }}
          onCompleted={() => {
            //Close Dialog
            setDialog({ ...dialog, open: false, type: 'CREATE' })
          }}
        >
          {mutate => {
            return (
              <CreateModal
                title="Edit Password"
                closeDialog={() => setDialog({ ...dialog, open: false })}
                open={dialog.open}
                FormContent={
                  <ChangePasswordWithFormik
                    onSubmit={mutate}
                    user={user.id}
                    type={dialog.type}
                  />
                }
              />
            )
          }}
        </Mutation>
      )}
    </React.Fragment>
  )
}

AccountsCreate.propTypes = {
  user: PropTypes.any,
  open: PropTypes.bool,
  type: PropTypes.string,
  dialog: PropTypes.object,
  setDialog: PropTypes.func.isRequired,
}
