import gql from 'graphql-tag'

export const LIST_ITEM_QUERY = gql`
  query ListItems($number: Int!, $limit: Int, $filter: String) {
    listItems(page: { number: $number, limit: $limit, filter: $filter }) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        id
        itemCode
        unitOfMeasure
        materialType
        unitCost
        sellingPrice
        description
      }
    }
  }
`

export const LIST_PURCHASE_ORDER_QUERY = gql`
  query ListPurchaseOrder(
    $number: Int!
    $limit: Int
    $filter: String
    $date: String
  ) {
    listPurchaseOrders(
      page: { number: $number, limit: $limit, filter: $filter, date: $date }
    ) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        id
        purchaseOrderNum
        requestedBy {
          id
          firstName
          lastName
          email
          role
          username
        }
        approvedBy {
          id
          firstName
          lastName
          email
          role
          username
        }
        receivedBy {
          id
          firstName
          lastName
          email
          role
          username
        }
        created_at
        updated_at
        status
        items {
          id
          item {
            id
            itemCode
            unitOfMeasure
            unitCost
            materialType
            sellingPrice
            description
          }
          quantity
          cost
        }
      }
    }
  }
`
