import React from 'react'
import { Dialog, Paper, DialogTitle } from '@material-ui/core'
import PropTypes from 'prop-types'

const CreateModal = ({ open, closeDialog, FormContent, title }) => (
  <Dialog
    open={open}
    onClose={closeDialog}
    style={{ width: 'auto' }}
    maxWidth="sm"
    scroll="paper"
  >
    {title && (
      <DialogTitle style={{ backgroundColor: '#2d3e50', color: '#FFFFFF' }}>
        {title}
      </DialogTitle>
    )}

    <Paper>{FormContent}</Paper>
  </Dialog>
)

CreateModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  FormContent: PropTypes.any,
}

export default CreateModal
