import gql from 'graphql-tag'

export const INVENTORY_ADJUSTMENT_MUTATION = gql`
  mutation InventoryAdjustment(
    $category: INVENTORY_ADJUSTMENT_CATEGORY!
    $reason: String
    $items: [InventoryAdjustmentItemInput]!
  ) {
    adjustInventory(
      input: { category: $category, reason: $reason, items: $items }
    ) {
      refCode
      category
      reason
      created_at
      items {
        refCode
        item {
          id
          itemCode
          unitOfMeasure
          materialType
          unitCost
          sellingPrice
          description
        }
        quantity
        created_at
      }
    }
  }
`
