import React, { useState, useEffect } from 'react'
import MaterialMasterTable from './components/Tables/MaterialMasterTable'
import { Container, Grid, Paper } from '@material-ui/core'
import { materialMasterStyles } from './assets/styles'
import ToolBar from './components/ToolBar'
import ItemCreate from './components/Actions/ItemCreate'
import Fab from './common/Fab'
import AddIcon from '@material-ui/icons/Add'
import { customTheme } from './assets/styles'
import { ThemeProvider } from '@material-ui/styles'

export default function MaterialMaster() {
  const classes = materialMasterStyles()

  let [selectedDate, handleDateChange] = useState(new Date())
  let [searchString, setSearch] = useState('')
  let [dialog, setDialog] = useState({
    open: false,
    type: 'CREATE',
    data: null,
  })
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => resize())

  window.addEventListener('resize', resize)

  function resize() {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={2}>
            {/* TOOLBAR */}
            <Grid item xs={12} md={12} lg={12}>
              <ToolBar
                isMobile={isMobile}
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                setSearch={setSearch}
                setDialog={setDialog}
                dialog={dialog}
                title="Material Master"
              />
            </Grid>
            {/* Material Taster Table */}
            <Grid item xs={12} md={12} lg={12}>
              <Paper>
                <MaterialMasterTable
                  isMobile={isMobile}
                  type={dialog.type}
                  date={selectedDate}
                  search={searchString}
                  setDialog={setDialog}
                  dialog={dialog}
                />
              </Paper>
            </Grid>
          </Grid>
        </Container>
        {isMobile && (
          <Fab
            color="primary"
            icon={<AddIcon></AddIcon>}
            onClick={() => setDialog({ ...dialog, open: true, type: 'CREATE' })}
          ></Fab>
        )}

        <ItemCreate dialog={dialog} setDialog={setDialog} item={dialog.data} />
      </ThemeProvider>
    </>
  )
}
