import React from 'react'
// import { TableSpacing } from '../Assets/styles'
import Table from '@material-ui/core/Table'

import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton } from '@material-ui/core'

export default function SimpleTable({ items, deleteItem }) {
  let subTotal = 0
  let discount = 0

  return (
    <div>
      <Paper style={{ overflowX: 'auto' }}>
        <Table style={{ overflowX: 'auto' }}>
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Selling Price</TableCell>
              <TableCell align="right">Price</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(row => {
              subTotal += parseInt(row.quantity * row.data.sellingPrice)
              discount += parseInt(row.discount)
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" value={row.id} scope="row">
                    {row.data.description}
                  </TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">{row.data.sellingPrice}</TableCell>
                  <TableCell align="right">
                    {row.quantity * row.data.sellingPrice}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => deleteItem(row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>

          <TableBody>
            {items.length !== 0 && (
              <>
                <TableRow>
                  <TableCell>Sub Total</TableCell>
                  <TableCell align="right" />

                  <TableCell align="right" />
                  <TableCell align="right" />
                  <TableCell align="right">{subTotal}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Discount</TableCell>
                  <TableCell align="right" />

                  <TableCell align="right" />
                  <TableCell align="right" />
                  <TableCell align="right">{discount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell align="right" />

                  <TableCell align="right" />
                  <TableCell align="right" />
                  <TableCell align="right">{subTotal - discount}</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}
SimpleTable.propTypes = {
  items: PropTypes.array.isRequired,
  deleteItem: PropTypes.func.isRequired,
}
