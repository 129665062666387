import React, { Fragment } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import Currency from 'react-currency-formatter'
import PropTypes from 'prop-types'
import { Query } from '@apollo/react-components'
import { GET_TENTATIVE_REPORT } from '../../../query/query'
import OptionsMenu from './OptionsMenu'
import Loading from '../../../common/Loading'

export default function DisplayTable({ date }) {
  return (
    <Fragment>
      <Query query={GET_TENTATIVE_REPORT} variables={{ date: date }}>
        {({ error, loading, data: { getTentativeReport } }) => {
          if (error) return 'error'
          if (loading) return <Loading />

          let {
            expenses: { items: expenses, total },
          } = getTentativeReport

          return (
            <Table style={{ overflow: 'auto' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Value</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expenses.length > 0 &&
                  expenses.map((item, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell>{item.description}</TableCell>
                        <TableCell align="right">
                          <Currency quantity={item.amount} currency="PHP" />
                        </TableCell>
                        <TableCell align="right">
                          <OptionsMenu item={item} />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                {expenses.length === 0 && (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={3}
                      style={{ padding: '50px' }}
                    >
                      No Data
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell>Total Expenses</TableCell>
                  <TableCell align="right">
                    <Currency quantity={parseInt(total)} currency="PHP" />
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          )
        }}
      </Query>
    </Fragment>
  )
}

DisplayTable.propTypes = {
  date: PropTypes.instanceOf(Date),
}
