import { makeStyles } from '@material-ui/core'

export const formStyle = makeStyles(theme => ({
  select: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

    textField: {
      marginRight: theme.spacing(1),
    },
  },
  addButton: {
    marginTop: 25,
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginLeft: 0,
    },
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  container: {
    position: 'relative',
    width: '100%',
    marginTop: '12px',
    padding: '4px 0px 7px',
    //  width: '100%',
  },

  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
}))
