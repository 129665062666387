import React from 'react'
import Fab from '@material-ui/core/Fab'
import PropTypes from 'prop-types'
import { Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  fab: {
    position: 'fixed',
    right: '25px',
    bottom: '50px',
  },
})

export default function FloatingActionButton({ color, icon, onClick }) {
  const classes = useStyles()
  return (
    <div>
      <Fab
        aria-label="custom-fab"
        className={classes.fab}
        color={color}
        onClick={onClick}
      >
        {icon}
      </Fab>
    </div>
  )
}

FloatingActionButton.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.instanceOf(Icon),
  onClick: PropTypes.func,
}
