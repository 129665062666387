import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import { fade, makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import { ThemeProvider } from '@material-ui/styles'
import { theme } from '../../assets/styles'
import { IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import EditIcon from '@material-ui/icons/EditRounded'
import AccountBox from '@material-ui/icons/AccountBox'

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    padding: '17px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  addItemBtn: {
    marginLeft: '10px',
    marginRight: '0px',
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
    backgroundColor: '#2d3e50',
    border: '1px solid #2d3e50',
    transition: '200ms ease-in-out',
    '&:hover': {
      color: '#FFFFFF!important',
      // backgroundColor: '#2d3e50!important',
      border: '1px solid white',
      // borderColor: '#FFFFFF!important',
    },
  },
}))

export default function ToolBar({ setSearch, setDialog, title, dialog }) {
  const classes = useStyles()

  const handleEditClick = type => {
    if (type === 'CREATE' || type === null) {
      setDialog({ ...dialog, type: 'EDIT' })
    } else {
      setDialog({ ...dialog, type: 'CREATE' })
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar variant="dense">
            {/* DatePicker Input */}
            <AccountBox style={{ marginRight: '5px' }} />
            <Typography className={classes.title} variant="h6" noWrap>
              {title}
            </Typography>
            {/* <Typography className={classes.title} variant="h6">
              <YearMonthPicker
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
              />
            </Typography> */}

            {/* Search Input */}
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={e => setSearch(e.target.value)}
                inputProps={{ 'aria-label': 'Search' }}
              />
            </div>

            {/* Add Items Button */}
            <Tooltip title="Add Accounts">
              <IconButton
                variant="text"
                className={classes.addItemBtn}
                onClick={() =>
                  setDialog({ ...dialog, open: true, type: 'CREATE' })
                }
              >
                <AddIcon />
              </IconButton>
            </Tooltip>

            {/* Edit Items Button */}
            <Tooltip title="Edit Item">
              <IconButton
                variant="text"
                className={classes.addItemBtn}
                onClick={() => handleEditClick(dialog.type)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  )
}

ToolBar.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  handleDateChange: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
  setDialog: PropTypes.func.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  dialog: PropTypes.object,
}
