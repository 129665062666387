import React, { Fragment, useState } from 'react'
import { Typography, Grid, Paper } from '@material-ui/core'
import { reportStyles } from '../../assets/styles'
import DisplayTable from './DisplayTable'
import ExpensesForm from './ExpensesForm'
import { Mutation } from '@apollo/react-components'
import { CREATE_EXPENSES_MUTATION } from '../../query/mutation'
import { ShowFlashMessage } from 'utils'
import moment from 'moment-timezone'

export default function Expenses({ date }) {
  // const classes = ReportsPageStyles()
  const classes = reportStyles()
  let [state, setState] = useState({
    expenses: [],
    totalExpenses: 0,
  })

  return (
    <Fragment>
      <Paper>
        <div className={classes.reportContainer}>
          <Grid container spacing={1}>
            <div className={classes.titleContainer}>
              <Typography variant="h6" className={classes.reportTitle}>
                Expenses
              </Typography>
              <Typography
                variant="subtitle1"
                className={classes.reportSubtitle}
              >
                for the month: {moment(date).format('MMMM, YYYY')}
              </Typography>
            </div>
            <Grid item xs={12}>
              <DisplayTable date={date} />
            </Grid>
            <Grid item xs={12}>
              <Mutation
                mutation={CREATE_EXPENSES_MUTATION}
                refetchQueries={['GetTentativeReport']}
                update={cache => {
                  ShowFlashMessage(cache, `Expenses Saved`)
                }}
                onCompleted={() => {
                  //Close Dialog
                }}
              >
                {(mutate, loading) => {
                  return (
                    <ExpensesForm
                      state={state}
                      expenses={state.expenses}
                      setState={setState}
                      mutate={mutate}
                      date={date}
                    />
                  )
                }}
              </Mutation>
            </Grid>
          </Grid>
        </div>
      </Paper>
    </Fragment>
  )
}
