import React, { useState, Fragment } from 'react'
import { reportStyles } from '../../assets/styles'
import {
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core'
import Currency from 'react-currency-formatter'
import Dialog from '../../common/CustomModal'
import AddExpensesForm from '../Actions/AddExpenses/AddExpensesForm'
import { GET_TENTATIVE_REPORT, LIST_REPORTS_QUERY } from '../../query/query'
import { Query } from '@apollo/react-components'
import CostOfGoodsSold from './CostOfGoodsSold'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import Confirm from './Confirm'
import Loading from '../../common/Loading'

export default function ProfitAndLossStatement({ date }) {
  const classes = reportStyles()
  let [TYPES] = React.useState({
    READ: 'READ',
    EDITABLE: 'EDITABLE',
    UPDATE: 'UPDATE',
  })

  let [type, setType] = useState(TYPES.READ)

  let [reportExists, setReportExists] = useState(false)
  let [openCogs, setOpenCogs] = useState(false)
  let [expenses, setExpenses] = useState([])
  let [totalExpenses, setTotalExpenses] = useState(0)
  let [cogsItems, setCogsItems] = useState(null)
  let [totalCogs, setTotalCogs] = useState(0)

  let [grossProfit, setGrossProfit] = useState(0)

  const [open, setOpen] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)

  const handleAddExpenses = values => {
    let newData = values
    let data = expenses
    data.push(newData)
    setExpenses(data)
  }

  const handleComputeProfitAndLoss = () => {
    return grossProfit - totalExpenses
  }

  return (
    <Fragment>
      <div className={classes.reportContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h6" className={classes.reportTitle}>
            Profit & Loss Statement for Sample Company, Inc.
          </Typography>
          <Typography variant="subtitle1" className={classes.reportSubtitle}>
            for the month: {moment(date).format('MMMM, YYYY')}
          </Typography>
        </div>
        {/* LIST REPORT QUERY */}
        <Query
          query={LIST_REPORTS_QUERY}
          variables={{
            number: 1,
            date: date,
          }}
        >
          {({ loading, error, data: { listPlReports } }) => {
            if (error) return 'error'
            if (loading) return <Loading />

            let {
              //data: reports,
              pageInfo: { total },
            } = listPlReports

            if (parseInt(total) === 1) {
              setReportExists(true)
            } else {
              setReportExists(false)
            }

            return (
              <Query query={GET_TENTATIVE_REPORT} variables={{ date: date }}>
                {({ error, loading, data: { getTentativeReport } }) => {
                  if (error) return 'error'
                  if (loading) return <Loading />

                  let {
                    cogsItems,
                    revenue,
                    grossProfit,
                    expenses: { total, items: expenses },
                    cogs,
                  } = getTentativeReport

                  if (cogsItems) {
                    setCogsItems(cogsItems)
                    setGrossProfit(grossProfit)
                    setTotalCogs(cogs)
                    setExpenses(expenses)
                    setTotalExpenses(total)
                  }

                  return (
                    <Table className={classes.tableStyle}>
                      <TableHead />
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={4} />
                        </TableRow>
                        {/* Revenues */}
                        <TableRow>
                          <TableCell colSpan={4} align="left">
                            <Typography
                              variant="body2"
                              className={classes.revenueTitle}
                            >
                              Revenue Stream
                            </Typography>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell colSpan={3} align="left">
                            <Typography
                              variant="body2"
                              className={classes.totalRevenue}
                            >
                              Total Revenue
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.costOfGoodsSold}
                              onClick={() => setOpenCogs(true)}
                            >
                              Cost of Goods Sold
                            </Typography>
                          </TableCell>

                          <TableCell colSpan={1} align="right">
                            <Typography
                              variant="body2"
                              className={classes.totalRevenue}
                            >
                              <Currency quantity={revenue} symbol="" />
                            </Typography>
                            <Typography
                              variant="body2"
                              className={classes.costOfGoodsSoldValue}
                            >
                              <Currency quantity={totalCogs} symbol="" />
                            </Typography>
                          </TableCell>
                        </TableRow>

                        {/* Gross Profit */}
                        <TableRow>
                          <TableCell colSpan={3}>
                            <Typography
                              variant="body2"
                              className={classes.grossProfit}
                            >
                              Gross Profit
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={1} align="right">
                            <Typography
                              variant="body2"
                              className={classes.grossProfit}
                            >
                              <Currency quantity={grossProfit} symbol="" />
                            </Typography>
                          </TableCell>
                        </TableRow>

                        {/* Expenses */}
                        <TableRow>
                          <TableCell colSpan={3} align="left">
                            <Typography
                              variant="body2"
                              className={classes.expensesTitle}
                            >
                              Expenses
                            </Typography>
                          </TableCell>

                          {/* EDIT REPORT BUTTON */}
                          <TableCell
                            align="center"
                            style={{ paddingLeft: '60px', maxWidth: '50px' }}
                          >
                            {reportExists && type === TYPES.UPDATE && (
                              <Button
                                style={{ margin: '0px' }}
                                variant="outlined"
                                color="secondary"
                                size="small"
                                onClick={() => setOpen(true)}
                              >
                                +
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                        {expenses && (
                          <TableRow>
                            <TableCell colSpan={3} align="left">
                              {expenses.map((item, index) => {
                                return (
                                  <Typography
                                    key={index}
                                    variant="body2"
                                    className={classes.expensesName}
                                  >
                                    {item.description}
                                  </Typography>
                                )
                              })}
                            </TableCell>

                            <TableCell colSpan={1} align="right">
                              {expenses.map((item, index) => {
                                return (
                                  <Typography
                                    key={index}
                                    variant="body2"
                                    className={classes.expensesValue}
                                  >
                                    <Currency
                                      quantity={parseInt(item.amount)}
                                      symbol=""
                                    />
                                  </Typography>
                                )
                              })}
                            </TableCell>
                          </TableRow>
                        )}

                        <TableRow align="center" className={classes.expenses}>
                          <TableCell colSpan={3} align="left">
                            <Typography
                              variant="body2"
                              className={classes.totalExpensesName}
                            >
                              Total Expenses
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={1} align="right">
                            <Typography
                              variant="body2"
                              className={classes.totalExpensesValue}
                            >
                              <Currency
                                quantity={totalExpenses}
                                currency="PHP"
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>

                        {/* Net Earnings */}
                        <TableRow>
                          <TableCell colSpan={3} align="left">
                            <Typography
                              variant="body2"
                              className={classes.profitAndLossName}
                            >
                              Net Earnings
                            </Typography>
                          </TableCell>
                          <TableCell colSpan={1} align="right">
                            <Typography
                              variant="body2"
                              className={classes.profitAndLoss}
                            >
                              <Currency
                                quantity={handleComputeProfitAndLoss()}
                                currency="PHP"
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>

                        {/* <TableFooter> */}
                        {reportExists && type === TYPES.READ && (
                          <TableRow>
                            <TableCell colSpan={4} align="center">
                              <Typography
                                variant="caption"
                                style={{ color: 'green' }}
                              >
                                Report has already been submitted for this
                                month.
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            {reportExists === false && type === TYPES.READ && (
                              <Button
                                variant="text"
                                color="primary"
                                onClick={() => setOpenConfirm(true)}
                              >
                                Submit Report
                              </Button>
                            )}
                            {/* {reportExists === true && type === TYPES.READ && (
                      <Fragment>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={() => setType(TYPES.UPDATE)}
                        >
                          Edit Report
                        </Button>
                      </Fragment>
                    )} */}
                            {reportExists === true && type === TYPES.UPDATE && (
                              <Fragment>
                                <Button variant="text" color="primary">
                                  Save
                                </Button>
                                <Button
                                  variant="text"
                                  color="secondary"
                                  onClick={() => setType(TYPES.READ)}
                                >
                                  Cancel
                                </Button>
                              </Fragment>
                            )}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      {/* </TableFooter> */}
                    </Table>
                  )
                }}
              </Query>
            )
          }}
        </Query>
        <div />
      </div>
      <Dialog
        open={open}
        closeDialog={() => setOpen(false)}
        title="Add another field"
        FormContent={
          <AddExpensesForm
            addExpenses={handleAddExpenses}
            closeDialog={() => setOpen(false)}
          />
        }
      />
      <Dialog
        open={openCogs}
        closeDialog={() => setOpenCogs(false)}
        title="Cost of Goods Sold"
        FormContent={<CostOfGoodsSold data={cogsItems} totalCogs={totalCogs} />}
      />
      <Dialog
        open={openConfirm}
        title="Confirmation"
        FormContent={
          <Confirm
            setOpenConfirm={setOpenConfirm}
            date={date}
            expenses={expenses}
            setType={setType}
            TYPES={TYPES}
          />
        }
      />
    </Fragment>
  )
}

ProfitAndLossStatement.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
}
