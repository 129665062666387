import React from 'react'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Mutation } from '@apollo/react-components'
import {
  VOID_EXPENSE_MUTATION,
  UPDATE_EXPENSES_MUTATION,
} from 'components/Dashboard/components/Reports/query/mutation'
import { ShowFlashMessage } from 'utils'
import CreateDialog from '../../../../common/CustomModal'
import ExpensesForm from '../../ExpensesForm'
import PropTypes from 'prop-types'

export default function OptionsMenu({ item }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const [mutationString, setMutationString] = React.useState(
    VOID_EXPENSE_MUTATION
  )
  const [isEdit, setIsEdit] = React.useState(false)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const updateExpense = mutate => {
    //code
    setMutationString(UPDATE_EXPENSES_MUTATION)
    handleClose()
    setIsEdit(true)
    setOpen(true)
  }

  const voidExpense = mutate => {
    setMutationString(VOID_EXPENSE_MUTATION)
    mutate({ variables: { id: item.id } })
  }

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Mutation
        mutation={mutationString}
        refetchQueries={['GetTentativeReport']}
        update={cache => {
          ShowFlashMessage(cache, `Expenses Updated`)
        }}
        onCompleted={() => {
          //Close Dialog
          handleClose()
          setOpen(false)
        }}
      >
        {(mutate, loading) => {
          return (
            <>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => updateExpense(mutate)}>
                  Update
                </MenuItem>
                <MenuItem onClick={() => voidExpense(mutate)}>
                  Void Expense
                </MenuItem>
              </Menu>
              <CreateDialog
                open={open}
                closeDialog={() => setOpen(false)}
                title={'Edit'}
                FormContent={
                  <ExpensesForm
                    editValues={item}
                    mutate={mutate}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                  />
                }
              />
            </>
          )
        }}
      </Mutation>
    </div>
  )
}

OptionsMenu.propTypes = {
  item: PropTypes.object.isRequired,
}
