import gql from 'graphql-tag'

export const CREATE_SALE_INVOICE_MUTATION = gql`
  mutation createSalesInvoice(
    $items: [SalesInvoiceItemInput!]!
    $date: String!
  ) {
    placeInvoice(input: { items: $items, date: $date }) {
      id
      soldBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      invoiceNum
      items {
        id
        item {
          id
          itemCode
          unitOfMeasure
          materialType
          unitCost
          sellingPrice
          description
        }
        quantity
        totalCost
        discount
      }
    }
  }
`
export const CREATE_ITEM_MUTATION = gql`
  mutation CreateItem(
    $id: Int
    $description: String!
    $unitOfMeasure: UM!
    $materialType: MT!
    $unitCost: Float!
    $sellingPrice: Float!
  ) {
    item(
      input: {
        id: $id
        description: $description
        unitOfMeasure: $unitOfMeasure
        materialType: $materialType
        unitCost: $unitCost
        sellingPrice: $sellingPrice
      }
    ) {
      id
      itemCode
      description
      unitOfMeasure
      materialType
      unitCost
      sellingPrice
    }
  }
`
export const VOID_INVOICE_MUTATION = gql`
  mutation deleteInvoice($invoiceId: Int, $password: String) {
    voidInvoice(invoiceId: $invoiceId, password: $password) {
      id
      soldTo
      soldBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      void
      totalPrice
      invoiceNum
      created_at
      updated_at
      items {
        id
        item {
          id
          itemCode
          unitOfMeasure
          materialType
          unitCost
          sellingPrice
          description
        }
        quantity
        totalCost
        totalPrice
        discount
        invoiceNum
        created_at
        updated_at
      }
    }
  }
`
