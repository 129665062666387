import React from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import ItemForm from './Form'
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Container,
} from '@material-ui/core'
import CreateTable from './Table'
import { Button } from '@material-ui/core'
import { CREATE_PURCHASE_ORDER_MUTATION } from '../../../mutation'
import { Mutation } from '@apollo/react-components'
import { ShowFlashMessage } from 'utils'
import _ from 'lodash'

export default function CreatePODialog({ open, setOpen }) {
  let [items, setItems] = React.useState([])

  const handleAddItem = item => {
    let itemExist = _.find(items, data => {
      return data.id === item.id
    })

    if (itemExist) {
      let index = _.findIndex(items, { id: item.id })
      itemExist.quantity += item.quantity
      items.splice(index, 1)
      item = itemExist
    }
    setItems(items.concat(item))
  }
  const handleResetItem = () => {
    setItems([])
    setOpen({ open: false })
  }

  const deleteItem = id => {
    let index = _.findIndex(items, { id: id })
    items.splice(index, 1)
    setItems(items.concat())
  }

  return (
    <Container style={{ padding: '20px' }}>
      <Dialog
        fullWidth
        style={{
          minHeight: '1000px !important',
          maxHeight: '1000px !important',
        }}
        open={open}
        onClose={handleResetItem}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        scroll="paper"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ backgroundColor: '#2d3e50', color: '#FFFFFF' }}
        >
          Add Purchase Order
        </DialogTitle>

        <DialogContent>
          <ItemForm setValue={handleAddItem} />
          {/* </DialogContent>
        <DialogContent> */}
          <CreateTable Itemvalues={items} deleteItem={deleteItem} />
        </DialogContent>
        <DialogActions>
          <Mutation
            mutation={CREATE_PURCHASE_ORDER_MUTATION}
            refetchQueries={['ListPurchaseOrder']}
            update={async cache => {
              ShowFlashMessage(cache, 'Purchase Order Succesfully Created')
              //await tableRef.onQueryChange()
              handleResetItem()
            }}
          >
            {mutate => {
              return (
                <Button
                  variant="outlined"
                  type="submit"
                  color="primary"
                  fullWidth
                  style={{
                    marginBottom: '10px',
                    color: '#2d3e50',
                  }}
                  onClick={() => {
                    let newItems = items.map(item => {
                      delete item.data
                      return item
                    })

                    mutate({ variables: { items: newItems } })
                  }}
                >
                  SUBMIT
                </Button>
              )
            }}
          </Mutation>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

CreatePODialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}
