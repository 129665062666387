import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import BarChartIcon from '@material-ui/icons/BarChart'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/styles'
import YearMonthPicker from '../../../MaterialMaster/components/ToolBar/datePicker'
import { IconButton, Tooltip } from '@material-ui/core'
import ListIcon from '@material-ui/icons/List'
import { toolBarStyles, theme } from './styles'
import { navigate } from '@reach/router'

export default function CustomToolBar({
  selectedDate,
  handleDateChange,
  setDialog,
  title,
}) {
  const classes = toolBarStyles()

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar variant="dense">
            {/* DatePicker Input */}
            <BarChartIcon style={{ marginRight: '15px' }} />
            <Typography className={classes.title} variant="h6">
              {title}
            </Typography>
            <Typography className={classes.title} variant="h6" noWrap>
              <YearMonthPicker
                selectedDate={selectedDate}
                handleDateChange={date => handleDateChange({ date: date })}
              />
            </Typography>

            {/* Add Items Button */}
            <Tooltip title="Reports List">
              <IconButton
                variant="text"
                className={classes.addItemBtn}
                onClick={() => navigate('/reports/reports-list')}
              >
                <ListIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  )
}

CustomToolBar.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  handleDateChange: PropTypes.func.isRequired,
  setSearch: PropTypes.func,
  setDialog: PropTypes.func,
  title: PropTypes.string,
  type: PropTypes.string,
}
