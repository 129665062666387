import React from 'react'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Typography,
  Box,
  CssBaseline,
  Avatar,
} from '@material-ui/core'

import LoginForm from './Form'
import { Mutation } from '@apollo/react-components'
import { LOGIN_MUTATION } from '../mutation'
import { ShowFlashMessage } from 'utils'
import { Redirect, navigate } from '@reach/router'

function Message() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Buy & Sell Inventory System '}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

export default function SignIn() {
  const classes = useStyles()
  if (localStorage.getItem('accessToken')) {
    return <Redirect to="/" noThrow />
  }
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Mutation
          mutation={LOGIN_MUTATION}
          update={(cache, { data: { auth } }) => {
            ShowFlashMessage(cache, `Welcome ${auth.user.firstName}`)
            localStorage.setItem('accessToken', auth.token)
            localStorage.setItem('user', JSON.stringify(auth.user))
            navigate('/')
          }}
        >
          {(mutate, { loading }) => {
            return <LoginForm onSubmit={mutate} loading={loading} />
          }}
        </Mutation>
      </div>
      <Box mt={5}>
        <Message />
      </Box>
    </Container>
  )
}
