import { makeStyles } from '@material-ui/core/styles'

export const ReportListStyle = makeStyles(theme => ({
  tableStyle: {
    [theme.breakpoints.down('xs')]: {
      overflowX: 'scroll',
      width: '100%',
    },
  },
}))
