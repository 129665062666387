import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

export default function SimpleTable({ Itemvalues, deleteItem }) {
  let total = 0
  return (
    <div>
      <Container
        style={{
          maxHeight: '200px',
          minHeight: '200px',
          overflow: 'auto',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Unit Cost</TableCell>
              <TableCell align="right">Total Cost</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Itemvalues.map(row => {
              total += parseInt(row.quantity * row.data.unitCost)
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" value={row.id} scope="row">
                    {row.data.description}
                  </TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">{row.data.unitCost}</TableCell>
                  <TableCell align="right">
                    {row.quantity * row.data.unitCost}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      variant="outlined"
                      onClick={() => deleteItem(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}

            {Itemvalues.length !== 0 && (
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right">{total}</TableCell>
                <TableCell align="right" />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Container>
    </div>
  )
}
SimpleTable.propTypes = {
  Itemvalues: PropTypes.array.isRequired,
  deleteItem: PropTypes.func.isRequired,
}
