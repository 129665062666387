import React, { Fragment } from 'react'
import { materialMasterTableStyle } from '../../assets/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
} from '@material-ui/core'
import { Query } from '@apollo/react-components'
import TablePaginationActions from './TablePaginationActions'
import PropTypes from 'prop-types'
import { LIST_USER_QUERY } from '../../queries'
import EditIcon from '@material-ui/icons/Edit'
import Security from '@material-ui/icons/Lock'
import { Tooltip } from '@material-ui/core'

export default function AccountsTable({ date, search, setDialog, dialog }) {
  const classes = materialMasterTableStyle()

  const [state, setState] = React.useState({
    open: false,
    page: 0,
    limit: 5,
  })

  const handleEditClick = row => {
    setDialog({ ...dialog, open: true, type: 'EDIT', data: row })
  }
  // Pagination Functions
  const handleChangePage = (event, page) => {
    setState({ ...state, page: page })
  }

  const handleChangeRowsPerPage = event => {
    setState({ ...state, limit: parseInt(event.target.value) })
  }

  return (
    <Fragment>
      <Paper style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <Query
          query={LIST_USER_QUERY}
          variables={{
            number: state.page + 1,
            limit: state.limit,
            date: date,
            filter: search,
          }}
        >
          {({ error, loading, data: { listUsers } }) => {
            if (error) return 'error'
            if (loading || !listUsers) return null
            let {
              data: users,
              pageInfo: { page, perPage, total },
            } = listUsers

            return (
              <Fragment>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left">
                        Id
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        First Name
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Last Name
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Email
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Username
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Role
                      </TableCell>
                      {dialog.type === 'EDIT' && (
                        <TableCell className={classes.tableCell} align="left">
                          Actions
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users.length === 0 && (
                      <TableCell
                        colSpan={10}
                        style={{
                          textAlign: 'center',
                          padding: '20px',
                        }}
                      >
                        No results found.
                      </TableCell>
                    )}
                    {users.map(row => (
                      <TableRow
                        hover
                        key={row.id}
                        onDoubleClick={() => handleEditClick(row)}
                      >
                        <TableCell align="left">{row.id}</TableCell>
                        <TableCell align="left">{row.firstName}</TableCell>
                        <TableCell align="left">{row.lastName}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.username}</TableCell>
                        <TableCell align="left">
                          {row.role.map((role, index) => {
                            return (
                              <Typography variant="body2" key={index}>
                                {role}
                              </Typography>
                            )
                          })}
                        </TableCell>
                        {dialog.type === 'EDIT' && (
                          <TableCell align="left" className={classes.editCell}>
                            <Tooltip title="Edit User">
                              <EditIcon
                                style={{ marginRight: '15px' }}
                                onClick={() =>
                                  setDialog({
                                    open: true,
                                    type: 'EDIT',
                                    data: row,
                                  })
                                }
                              />
                            </Tooltip>
                            <Tooltip title="Change Password">
                              <Security
                                onClick={() =>
                                  setDialog({
                                    open: true,
                                    type: 'PASSWORD',
                                    data: row,
                                  })
                                }
                              />
                            </Tooltip>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  align="right"
                  style={{
                    alignContent: 'right',
                    alignItems: 'right',
                    alignSelf: 'right',
                    textAlign: 'right',
                  }}
                  rowsPerPageOptions={[5, 10, 20]}
                  count={parseInt(total)}
                  rowsPerPage={parseInt(perPage)}
                  page={page - 1}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  component="div"
                />
              </Fragment>
            )
          }}
        </Query>
      </Paper>
    </Fragment>
  )
}

AccountsTable.propTypes = {
  date: PropTypes.instanceOf(Date),
  search: PropTypes.string,
  setDialog: PropTypes.func,
  dialog: PropTypes.object,
}
