import gql from 'graphql-tag'

export const LOGIN_MUTATION = gql`
  mutation Login($username: String!, $password: String!) {
    auth(username: $username, password: $password) {
      token
      user {
        id
        firstName
        lastName
        email
        role
        username
      }
    }
  }
`
