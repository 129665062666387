import gql from 'graphql-tag'

export const LIST_USER_QUERY = gql`
  query ListUsers($number: Int, $limit: Int, $filter: String, $date: String) {
    listUsers(
      page: { number: $number, limit: $limit, filter: $filter, date: $date }
    ) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        id
        firstName
        lastName
        email
        role
        username
      }
    }
  }
`
