import { makeStyles } from '@material-ui/core/styles'

//Loading
export const LoadingStyle = makeStyles(() => ({
  loadingContainer: {
    width: '100%',
    display: 'block',
    padding: '15px',
    textAlign: 'center',
  },

  loadingSize: {
    width: '150px',
    height: '150px',
  },
}))

// Reports Page
export const ReportsPageStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  toolbar: {
    width: '100%',
    marginBottom: '20px',
    padding: '20px',
    display: 'inline-block',
  },
}))

// Report Paper Container
export const reportStyles = makeStyles(theme => ({
  tableStyle: {
    width: '100%',
    margin: '0px',
    [theme.breakpoints.down('xxs')]: {
      marginRight: '20px',
      paddingLeft: '0px',
      marginLeft: '0px',
      width: '100px',
    },
  },

  reportContainer: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingRight: '5px',
      paddingLeft: '0px',
      marginLeft: '0px',
      overflowX: 'scroll',
    },
  },

  titleContainer: {
    display: 'block',
    padding: 'auto',
    width: '100%',
    marginBottom: '40px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },

  reportTitle: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  reportSubtitle: {},
  revenueTitle: {
    fontWeight: 'bold',
  },
  totalRevenue: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  costOfGoodsSold: {
    marginTop: '40px',
    paddingLeft: theme.spacing(5),
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  costOfGoodsSoldValue: {
    color: 'red',
    marginTop: '40px',
    paddingLeft: theme.spacing(5),
  },
  grossProfit: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(5),
  },
  revenueName: {
    paddingLeft: theme.spacing(5),
  },

  expensesTitle: {
    fontWeight: 'bold',
  },
  expensesName: {
    marginTop: '4px',
    marginBottom: '4px',
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  expensesValue: {
    marginTop: '4px',
    marginBottom: '4px',
    color: 'red',
    paddingLeft: theme.spacing(5),
  },
  expenses: {
    color: 'red',
  },
  totalExpensesName: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  totalExpensesValue: {
    color: 'red',
    fontWeight: 'bold',
    paddingLeft: theme.spacing(5),
  },

  profitAndLoss: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(5),
  },

  profitAndLossName: {
    fontWeight: 'bold',
  },
}))

// Table
export const materialMasterTableStyle = makeStyles(theme => ({
  tableHeader: {
    marginLeft: '20px',
    width: 'max-content',
    fontWeight: '1',
    datePicker: {
      marginLeft: '30px',
    },
  },
  tableCell: {
    color: '#2d3e50',
  },
  tableCellHeaderText: {
    backgroundColor: '#FFFFFF',
  },
  editCell: {
    cursor: 'pointer',
  },
}))
