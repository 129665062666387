import gql from 'graphql-tag'

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation updateAccount(
    $firstName: String
    $lastName: String
    $email: String
    $username: String
    $password: String
  ) {
    updateProfile(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        username: $username
        password: $password
      }
    ) {
      id
      firstName
      lastName
      email
      role
      username
    }
  }
`
export const UPDATE_PASSWORD_MUTATION = gql`
  mutation password($oldPassword: String!, $newPassword: String!) {
    changePassword(
      input: { oldPassword: $oldPassword, newPassword: $newPassword }
    ) {
      id
      firstName
      lastName
      email
      role
      username
    }
  }
`
