import gql from 'graphql-tag'

export const LIST_ITEMS_QUERY = gql`
  query ListItems($number: Int!, $limit: Int, $filter: String) {
    listItems(page: { number: $number, limit: $limit, filter: $filter }) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        id
        itemCode
        unitOfMeasure
        materialType
        unitCost
        sellingPrice
        description
      }
    }
  }
`
export const LIST_MATERIAL_MASTER = gql`
  query ListMaterialMaster(
    $number: Int
    $limit: Int
    $filter: String
    $date: String
  ) {
    listMaterialMaster(
      page: { number: $number, limit: $limit, filter: $filter, date: $date }
    ) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        id
        description
        itemCode
        unitOfMeasure
        materialType
        unitCost
        sellingPrice
        openingStock
        closingStock
        in
        out
      }
    }
  }
`
export const GET_PURCHASE_ITEMS = gql`
  query GetPurchaseItems(
    $number: Int
    $limit: Int
    $filter: String
    $date: String
    $itemId: Int!
  ) {
    getPurchaseItems(
      page: { number: $number, limit: $limit, filter: $filter, date: $date }
      itemId: $itemId
    ) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        id
        item {
          id
          itemCode
          unitOfMeasure
          materialType
          unitCost
          sellingPrice
          description
        }
        quantity
        cost
        received
        purchaseOrderNum
        created_at
        updated_at
      }
    }
  }
`
export const GET_SALES_INVOICE_ITEMS = gql`
  query GetSalesInvoiceItems(
    $number: Int
    $limit: Int
    $filter: String
    $date: String
    $itemId: Int!
  ) {
    getSalesInvoiceItems(
      page: { number: $number, limit: $limit, filter: $filter, date: $date }
      itemId: $itemId
    ) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        id
        item {
          id
          itemCode
          unitOfMeasure
          materialType
          unitCost
          sellingPrice
          description
        }
        quantity
        totalCost
        discount
        invoiceNum
        created_at
        updated_at
      }
    }
  }
`

export const GET_ITEM_INVENTORY_ADJUSTMENT = gql`
  query GetItemInventoryAdjustment($page: Page, $itemId: Int) {
    getItemInventoryAdjustment(page: $page, itemId: $itemId) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        refCode
        item {
          id
          itemCode
          unitOfMeasure
          materialType
          unitCost
          sellingPrice
          description
        }
        quantity
        created_at
      }
    }
  }
`
