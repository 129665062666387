/* eslint-disable no-undef */
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'

export default function MaterialTableDemo({ invoice }) {
  return (
    <div>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Discount</TableCell>

              <TableCell align="right">Selling Price</TableCell>
              <TableCell align="right">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoice.items.map(row => {
              return (
                <TableRow key={row.id}>
                  <TableCell component="th" value={row.id} scope="row">
                    {row.item.description}
                  </TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">{row.discount}</TableCell>

                  <TableCell align="right">{row.item.sellingPrice}</TableCell>
                  <TableCell align="right">
                    {row.totalPrice - row.discount}
                  </TableCell>
                </TableRow>
              )
            })}

            {invoice.length !== 0 && (
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />

                <TableCell align="right">{invoice.totalPrice}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    </div>
  )
}

MaterialTableDemo.propTypes = {
  invoice: PropTypes.array.isRequired,
}
