import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/styles'
import YearMonthPicker from '../../../MaterialMaster/components/ToolBar/datePicker'
import { toolBarStyles, theme } from './styles'
import { InputBase, IconButton, Tooltip } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/PlaylistAdd'
import InventoryAdjustmentIcon from '@material-ui/icons/Exposure'

export default function ToolBar({
  selectedDate,
  handleDateChange,
  title,
  setSearch,
  setOpen,
  isMobile,
}) {
  const classes = toolBarStyles()

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar variant="dense">
            {/* DatePicker Input */}
            {!isMobile && (
              <>
                <InventoryAdjustmentIcon
                  style={
                    !isMobile ? { marginRight: '15px' } : { marginRight: '0px' }
                  }
                />
                <Typography className={classes.title} variant="h6">
                  {title}
                </Typography>
              </>
            )}
            <Typography className={classes.date} variant="h6" noWrap>
              <YearMonthPicker
                isMobile={isMobile}
                selectedDate={selectedDate}
                handleDateChange={date => handleDateChange(date)}
              />
            </Typography>

            {/* Search Input */}
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                onChange={e => setSearch(e.target.value)}
                inputProps={{ 'aria-label': 'Search' }}
              />
            </div>
            {/* Add Items Button */}
            {!isMobile && (
              <Tooltip title="Add Data">
                <IconButton
                  variant="text"
                  className={classes.addItemBtn}
                  onClick={() => setOpen(true)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </Toolbar>
        </AppBar>
      </div>
    </ThemeProvider>
  )
}

ToolBar.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  handleDateChange: PropTypes.func.isRequired,
  setSearch: PropTypes.func,
  dialog: PropTypes.object,
  setOpen: PropTypes.func,
  title: PropTypes.string,
  isMobile: PropTypes.bool,
}
