import React, { Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import PropTypes from 'prop-types'
import { Mutation } from '@apollo/react-components'
import {
  APPROVE_ORDER_MUTATION,
  RECEIVE_ORDER_MUTATION,
  REJECT_ORDER_MUTATION,
} from '../../../../mutation'
import { ShowFlashMessage } from 'utils'

const ITEM_HEIGHT = 48

export default function LongMenu({ id, status }) {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }
  function handleClose() {
    setAnchorEl(null)
  }

  return (
    <Fragment>
      {status === 'completed' && (
        <IconButton
          aria-label="More"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      )}

      {status === 'rejected' && (
        <IconButton
          aria-label="More"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      )}

      {status === 'pending' && (
        <div>
          <IconButton
            aria-label="More"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}
          >
            {/* <ApproveButton orderId={id} /> */}
            <Mutation
              mutation={APPROVE_ORDER_MUTATION}
              refetchQueries={['ListPurchaseOrder']}
              onCompleted={() => setAnchorEl(null)}
              update={cache => {
                ShowFlashMessage(cache, 'Succesfully Approved')
              }}
            >
              {mutate => {
                return (
                  <MenuItem
                    onClick={() => {
                      mutate({
                        variables: {
                          orderId: parseInt(id),
                        },
                      })
                    }}
                  >
                    APPROVE
                  </MenuItem>
                )
              }}
            </Mutation>
            <Mutation
              mutation={REJECT_ORDER_MUTATION}
              refetchQueries={['ListPurchaseOrder']}
              onCompleted={() => setAnchorEl(null)}
              update={cache => {
                ShowFlashMessage(cache, 'Succesfully Rejected')
              }}
            >
              {mutate => {
                return (
                  <MenuItem
                    onClick={() => {
                      mutate({
                        variables: {
                          orderId: parseInt(id),
                        },
                      })
                    }}
                  >
                    REJECT
                  </MenuItem>
                )
              }}
            </Mutation>
          </Menu>
        </div>
      )}
      {status === 'processing' && (
        <div>
          <IconButton
            aria-label="More"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 200,
              },
            }}
          >
            {/* <ReceiveButton orderId={id} /> */}
            <Mutation
              mutation={RECEIVE_ORDER_MUTATION}
              refetchQueries={['ListPurchaseOrder']}
              onCompleted={() => setAnchorEl(null)}
              update={cache => {
                ShowFlashMessage(cache, 'Succesfully Received')
              }}
            >
              {mutate => {
                return (
                  <MenuItem
                    onClick={() => {
                      mutate({
                        variables: {
                          orderId: parseInt(id),
                        },
                      })
                    }}
                  >
                    RECEIVE
                  </MenuItem>
                )
              }}
            </Mutation>
          </Menu>
        </div>
      )}
    </Fragment>
  )
}

LongMenu.propTypes = {
  status: PropTypes.string.isRequired,
  id: PropTypes.number,
}
