import React from 'react'
import { CREATE_ITEM_MUTATION } from '../../queries/mutations'
import { Mutation } from '@apollo/react-components'
import ItemAddWithFormik from './AddItemForm'
import { ShowFlashMessage } from 'utils'
import PropTypes from 'prop-types'
import CreateModal from '../../common/CustomModal'

export default function ItemCreate({ dialog, setDialog, item }) {
  return (
    <Mutation
      mutation={CREATE_ITEM_MUTATION}
      refetchQueries={['ListMaterialMaster', 'ListItems']}
      update={(cache, { data: { item } }) => {
        if (item) {
          ShowFlashMessage(cache, `Item successfuly added`)
        }
      }}
      onCompleted={() => {
        //Close Dialog
        setDialog({ ...dialog, open: false, type: 'CREATE' })
      }}
    >
      {mutate => {
        if (dialog.type === 'EDIT') {
          return (
            <CreateModal
              title="Edit Item"
              closeDialog={() =>
                setDialog({ ...dialog, open: false, type: 'EDIT' })
              }
              open={dialog.open}
              FormContent={<ItemAddWithFormik onSubmit={mutate} item={item} />}
            />
          )
        } else if (dialog.type === 'CREATE') {
          return (
            <CreateModal
              title="Add Item"
              closeDialog={() =>
                setDialog({ ...dialog, open: false, type: 'CREATE' })
              }
              open={dialog.open}
              FormContent={<ItemAddWithFormik onSubmit={mutate} />}
            />
          )
        } else {
          return null
        }
      }}
    </Mutation>
  )
}

ItemCreate.propTypes = {
  item: PropTypes.any,
  dialog: PropTypes.object,
  setDialog: PropTypes.func,
}
