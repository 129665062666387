import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import { customCalenderTheme } from '../../assets/styles'
import { Typography } from '@material-ui/core'

function YearMonthPicker({ selectedDate, handleDateChange }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Typography style={{ padding: '5px', float: 'left', lineHeight: '3.5' }}>
        DATE:
      </Typography>
      <ThemeProvider theme={customCalenderTheme}>
        <Fragment>
          <DatePicker
            autoOk
            disableFuture
            value={selectedDate}
            onChange={handleDateChange}
            style={{ margin: '16px' }}
          />
        </Fragment>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

YearMonthPicker.propTypes = {
  selectedDate: PropTypes.any,
  handleDateChange: PropTypes.func.isRequired,
}

export default YearMonthPicker
