import React, { Fragment } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import * as Yup from 'yup'
import { withFormik, Form } from 'formik'
import PropTypes from 'prop-types'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Button } from '@material-ui/core'

function DeleteForm(props) {
  let { errors, handleBlur, touched, values, handleChange, handleClose } = props
  const [state, setState] = React.useState({
    showPassword: false,
  })

  const handleClickShowPassword = () => {
    setState({ ...state, showPassword: !state.showPassword })
  }
  return (
    <Fragment>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <TextField
              id="outlined-adornment-oldpassword"
              variant="outlined"
              type={state.showPassword ? 'text' : 'password'}
              label="Confirm Password"
              name="password"
              value={values.password}
              onChange={handleChange}
              error={errors.password && touched.password}
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="Toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} />
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              fullWidth
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Button
              onClick={handleClose}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Fragment>
  )
}
DeleteForm.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
}
const Formik = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ password, invoiceId }) {
    return {
      password: password || '',
      invoiceId: invoiceId || '',
    }
  },
  validationSchema: Yup.object().shape({
    password: Yup.string().required('Password is required'),
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit({ variables: values })
  },
})(DeleteForm)

export default Formik
