import React from 'react'
import { withFormik, Form } from 'formik'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { AddButton, QuantityField, DiscountField } from '../../assets/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import MenuItem from '@material-ui/core/MenuItem'
import { Query } from '@apollo/react-components'
import { LIST_ITEMS_QUERY } from '../../../../query'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.description, query)
  const parts = parse(suggestion.description, matches)
  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
    display: 'inline-block',
    width: '100%',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
}))
function CreateForm(props) {
  let {
    errors,
    handleBlur,
    touched,
    values,
    handleChange,
    setFieldValue,
  } = props

  const classes = useStyles()

  const [search, setSearch] = React.useState('')

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSearch(value)
  }

  const getSuggestionValue = suggestion => {
    values.id = suggestion.id
    values.data = suggestion
    return suggestion.description
  }
  const handleSuggestionsClearRequested = () => {}

  const autosuggestProps = {
    renderInputComponent,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,

    getSuggestionValue,
    renderSuggestion,
  }

  return (
    <Form>
      <Grid container spacing={2}>
        <Query
          query={LIST_ITEMS_QUERY}
          variables={{ number: 1, size: 20, filter: search }}
        >
          {({ data: { listItems } }) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Autosuggest
                  suggestions={listItems ? listItems.data : []}
                  {...autosuggestProps}
                  inputProps={{
                    classes,
                    error: errors.search && touched.search,
                    helperText: touched.search && errors.search,
                    id: 'react-autosuggest-simple',
                    label: 'Items',
                    placeholder: 'Search Items',
                    name: 'search',
                    value: values.search,
                    onChange: (e, { newValue }) => {
                      setFieldValue('search', newValue)
                    },
                  }}
                  theme={{
                    container: classes.container,
                    suggestionsContainerOpen: classes.suggestionsContainerOpen,
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                  }}
                  renderSuggestionsContainer={options => (
                    <Paper
                      style={{ width: '100%' }}
                      {...options.containerProps}
                    >
                      {options.children}
                    </Paper>
                  )}
                />
              </Grid>
            )
          }}
        </Query>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <QuantityField
            placeholder="Quantity"
            name="quantity"
            id="standard-with-placeholder"
            label="Quantity"
            onBlur={handleBlur}
            error={errors.quantity && touched.quantity}
            helperText={touched.quantity && errors.quantity}
            type="number"
            value={values.quantity}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <DiscountField
            placeholder="Discount"
            name="discount"
            id="standard-with-placeholder"
            label="Discount"
            onBlur={handleBlur}
            type="number"
            value={values.discount}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <AddButton
            className="btn btn-primary"
            variant="outlined"
            color="primary"
            type="submit"
            fullWidth
            style={{
              marginBottom: '20px',
              color: '#2d3e50',
              marginTop: '20px',
            }}
          >
            Add
          </AddButton>
        </Grid>
      </Grid>
    </Form>
  )
}
CreateForm.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,

  setFieldValue: PropTypes.func.isRequired,
}
const Formik = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ quantity }) {
    return {
      quantity: quantity || '',
      discount: 0,
      search: '',
    }
  },
  validationSchema: Yup.object().shape({
    quantity: Yup.number()
      .positive()
      .integer()
      .required('Quantity is required'),
    search: Yup.string().required('Please Enter Item'),
  }),
  handleSubmit: (values, { props, resetForm }) => {
    props.setValue(values)
    resetForm()
  },
})(CreateForm)

export default Formik
