/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react'
import { Form, withFormik } from 'formik'
import { TextField, Button } from '@material-ui/core'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { addItemFormStyles, theme } from '../../../../assets/styles'
import { ThemeProvider } from '@material-ui/styles'

const ChangePasswordForm = ({
  values,
  handleChange,
  errors,
  handleBlur,
  touched,
}) => {
  const classes = addItemFormStyles()
  return (
    <ThemeProvider theme={theme}>
      <Form className={classes.container} autoComplete="off">
        <TextField
          label="New Password"
          name="newPassword"
          id="user-newPassword"
          onChange={handleChange}
          className={classes.descriptionField}
          value={values.newPassword}
          margin="normal"
          type="password"
          onBlur={handleBlur}
          error={errors.newPassword && touched.newPassword}
          helperText={errors.newPassword}
        />

        <Button
          variant="outlined"
          type="submit"
          color="primary"
          fullWidth
          style={{ marginBottom: '20px', color: '#2d3e50', marginTop: '20px' }}
        >
          Submit
        </Button>
      </Form>
    </ThemeProvider>
  )
}

ChangePasswordForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  type: PropTypes.any,
}

const ChangePasswordWithFormik = withFormik({
  mapPropsToValues: props => {
    return {
      id: props.user,
      newPassword: '',
    }
  },

  // Validation
  validationSchema: () => {
    let validation = {
      newPassword: Yup.string().required('Please enter your new Password'),
    }
    return Yup.object().shape(validation)
  },
  enableReinitialize: true,

  handleSubmit(variables, { props }) {
    props.onSubmit({ variables })
  },
})(ChangePasswordForm)

export default ChangePasswordWithFormik
