import styled from 'styled-components'
import { Grid, Dialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Fab, Button, TextField, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core'

export const PageWrapper = styled(Grid)``

export const AddItemButtonWrapper = styled.div`
  margin-left: 10px;
`

export const CreateDialog = withStyles({
  paper: {
    width: '400px',
  },
})(Dialog)

export const CreateFab = styled(Fab)`
  position: absolute !important;

  right: 35px !important;
  top: 92px !important;
  z-index: 10000;
`

export const AddButton = styled(Button)`
  margin-left: 10% !important;
`

export const CenterWrapper = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10%;
`

export const ItemField = styled(TextField)``
export const QuantityField = styled(TextField)`
  margin-left: 45% !important;
`
export const PriceField = styled(TextField)`
  width: 15%;
  margin-left: 1% !important;
`
export const SoldByTextField = styled(TextField)`
  margin-left: 5% !important;
`
export const ItemFieldSearch = styled(TextField)`
  width: 40% !important;
  margin-top: -52px !important;
`

export const PriceSpacing = styled.div`
  margin-left: 55%;
  position: absolute;
`

export const PaperSize = styled(Paper)`
margin-top: -8% !important
  width: 40% !important;
  position: absolute !important;
`
export const SubmitButton = styled(Button)`
  margin-left: 75% !important;
  margin-top: -55px !important;
`
export const InvoiceName = styled(TextField)`
  width: 40% !important;
  margin-top: 25px !important;
`
export const materialMasterStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  addButton: {
    paddingTop: '10px',
    paddingBottom: '10px',
    cursor: 'pointer',
  },
  toolbar: {
    width: '100%',
    marginBottom: '20px',
    padding: '20px',
    display: 'inline-block',
  },
  searchInput: {
    float: 'left',
  },
}))

export const materialTableStyles = makeStyles(() => ({
  titleStyle: {
    fontSize: '20px',
  },
}))

export const materialDatePickerStyles = makeStyles(() => ({
  calendarBigText: {
    color: '#2d3e50',
  },
  calendarSmallText: {
    color: '#d3e50',
  },
  calendarBackground: {
    backgroundColor: '#FFFFFF',
  },
}))

export const customCalenderTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        color: '#FFFFFF',
        backgroundColor: '#2d3e50',
      },
    },
    MuiInputBase: {
      input: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
    },

    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
      toolbarBtnSelected: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: '#2d3e50',
      },
    },
    MuiPickersMonth: {
      monthSelected: {
        color: '#2d3e50',
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: '#2d3e50',
      },
    },
  },
})

export const materialMasterTableStyle = makeStyles(theme => ({
  tableHeader: {
    marginLeft: '20px',
    width: 'max-content',
    fontWeight: '1',
    datePicker: {
      marginLeft: '30px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },

  tableCell: {
    color: '#2d3e50',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
    },
  },
  tableCellHeaderText: {
    backgroundColor: '#FFFFFF',
  },
  editCell: {
    cursor: 'pointer',
  },
}))

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2d3e50',
    },
  },
})

export const addItemBtn = makeStyles(() => ({
  addItemBtn: {
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
    '&:hover': {
      color: '#2d3e50!important',
      backgroundColor: '#FFFFFF!important',
      borderColor: '#2d3e50!important',
    },
  },
}))
export const addItemFormStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  },
  descriptionField: {
    width: '230px',
    [theme.breakpoints.down('xs')]: {
      width: '250px',
    },
  },
  materialTypeField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    width: '40%',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '30%',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}))
