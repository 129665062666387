import React from 'react'
import { Query } from '@apollo/react-components'
import { GET_SALES_INVOICE_ITEMS } from '../../queries/queries'
import moment from 'moment-timezone'
import Currency from 'react-currency-formatter'
import Loading from '../../../Reports/common/Loading'
import PropTypes from 'prop-types'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'

export default function SalesInvoiceRecordsTable({ itemId }) {
  return (
    <React.Fragment>
      {itemId && (
        <>
          <Query
            query={GET_SALES_INVOICE_ITEMS}
            variables={{ itemId: itemId }}
            fetchPolicy="cache-and-network"
          >
            {({ error, loading, data: { getSalesInvoiceItems } }) => {
              if (error) return `Error while fetching data`
              if (loading) return <Loading />

              if (!getSalesInvoiceItems) return null

              let {
                data: items,
                //pageInfo: { page },
              } = getSalesInvoiceItems

              return (
                <Table style={{ overflow: 'auto' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Item Code</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Sales Invoice</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Total Cost</TableCell>
                      <TableCell>Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items &&
                      items.map((item, key) => {
                        return (
                          <TableRow key={key}>
                            <TableCell>{item.item.itemCode}</TableCell>
                            <TableCell>{item.item.description}</TableCell>
                            <TableCell>{item.invoiceNum}</TableCell>
                            <TableCell>{item.quantity}</TableCell>
                            <TableCell>
                              <Currency
                                quantity={item.totalCost}
                                currency="PHP"
                              />
                            </TableCell>
                            <TableCell>
                              {moment(item.created_at).format('DD MMM, YYYY')}
                            </TableCell>
                          </TableRow>
                        )
                      })}
                  </TableBody>
                </Table>
              )
            }}
          </Query>
        </>
      )}
    </React.Fragment>
  )
}

SalesInvoiceRecordsTable.propTypes = {
  itemId: PropTypes.number,
}
