import React, { Component } from 'react'
import Main from './components'
import { PageWrapper } from './assets/styles'

export default class PurchaseOrder extends Component {
  render() {
    return (
      <PageWrapper>
        <Main {...this.props} />
      </PageWrapper>
    )
  }
}
