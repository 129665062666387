import React from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const tableStyles = makeStyles(theme => ({
  nodata: { padding: '20px 0px' },
  table: {
    width: 500,
    minWidth: 300,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      minWidth: '100%',
    },
  },
}))

export default function ItemsTable({ items }) {
  const classes = tableStyles()
  return (
    <div>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Item ID</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items &&
            items.map((adjustment, key) => {
              return (
                <TableRow key={key}>
                  <TableCell>{adjustment.refCode}</TableCell>
                  <TableCell>{adjustment.item.description}</TableCell>
                </TableRow>
              )
            })}
          {!items ||
            (items.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={2}
                  align="center"
                  className={classes.nodata}
                >
                  No Data
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  )
}

ItemsTable.propTypes = {
  items: PropTypes.array,
}
