import gql from 'graphql-tag'

export const LIST_ITEMS_QUERY = gql`
  query getItem($number: Int, $limit: Int, $filter: String) {
    listItems(page: { number: $number, limit: $limit, filter: $filter }) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        id
        itemCode
        unitOfMeasure
        materialType
        unitCost
        sellingPrice
        description
      }
    }
  }
`
export const LIST_SALE_INVOICE_QUERY = gql`
  query salesInvoice(
    $number: Int
    $limit: Int
    $filter: String
    $date: String
  ) {
    listSalesInvoice(
      page: { number: $number, limit: $limit, filter: $filter, date: $date }
    ) {
      totalPrice
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        id
        totalPrice
        soldTo
        soldBy {
          id
          firstName
          lastName
          email
          role
          username
        }
        void
        invoiceNum
        created_at
        updated_at
        items {
          id
          item {
            id
            itemCode
            unitOfMeasure
            materialType
            unitCost
            sellingPrice
            description
          }
          totalPrice
          quantity
          totalCost
          discount
        }
      }
    }
  }
`
export const LIST_INCOME_QUERY = gql`
  query listIncome($number: Int, $limit: Int, $filter: String, $date: String) {
    listSales(
      page: { number: $number, filter: $filter, limit: $limit, date: $date }
    ) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        date
        sales
      }
    }
  }
`
