import React from 'react'
import {
  Typography,
  Box,
  Tab,
  Tabs,
  AppBar,
  makeStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import WriteOffTable from '../Tables/WriteOffTable'
import SalesInvoiceTable from '../Tables/SalesInvoiceRecordsTable'

export const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      <Box p={3} style={{ padding: '0px' }}>
        {children}
      </Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

export function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  }
}

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  fixedSize: {
    width: '600px',
    overflowX: 'scroll',
    padding: 0,
    height: '400px',
  },
}))

export default function OutDialog({ itemId, isMobile }) {
  const classes = useStyles()

  const [value, setValue] = React.useState('one')

  function handleChange(event, newValue) {
    setValue(newValue)
  }

  return (
    <>
      <div className={classes.root}>
        <AppBar position={isMobile ? 'fixed' : 'static'} color="inherit">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="wrapped label tabs example"
          >
            <Tab
              value="one"
              label="Sales Invoice Records"
              wrapped
              {...a11yProps('one')}
            />
            <Tab value="two" label="Write-off" {...a11yProps('two')} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index="one" className={classes.fixedSize}>
          <SalesInvoiceTable itemId={itemId}></SalesInvoiceTable>
        </TabPanel>
        <TabPanel value={value} index="two" className={classes.fixedSize}>
          <WriteOffTable itemId={itemId}></WriteOffTable>
        </TabPanel>
      </div>
    </>
  )
}

OutDialog.propTypes = {
  itemId: PropTypes.number,
  isMobile: PropTypes.bool,
}
