import React, { Fragment } from 'react'
import { Typography, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Mutation } from '@apollo/react-components'
import { GENERATE_REPORT_MUTATION } from '../../query/mutation'
import { ShowFlashMessage } from 'utils'

export default function Confirm({
  setOpenConfirm,
  date,
  expenses,
  setType,
  TYPES,
}) {
  return (
    <Fragment>
      <Typography
        variant="h6"
        style={{
          marginTop: '10px',
          marginBottom: '10px',
          fontSize: '14px',
          width: '500px',
        }}
      >
        Confirm report submission for the month?
      </Typography>
      <div
        style={{
          display: 'inline-block',
          textAlign: 'right',
          padding: '10px',
          width: '100%',
        }}
      >
        <Mutation
          mutation={GENERATE_REPORT_MUTATION}
          refetchQueries={['ListReports']}
          update={cache => {
            ShowFlashMessage(cache, `Report Submitted`)
          }}
          onCompleted={() => {
            //Close Dialog
            setType(TYPES.READ)
            setOpenConfirm(false)
          }}
        >
          {mutate => {
            return (
              <Button
                variant="text"
                color="primary"
                onClick={() =>
                  mutate({
                    variables: {
                      date: date,
                      expenses: expenses,
                    },
                  })
                }
              >
                Submit
              </Button>
            )
          }}
        </Mutation>
        <Button
          variant="text"
          color="secondary"
          onClick={() => setOpenConfirm(false)}
        >
          Cancel
        </Button>
      </div>
    </Fragment>
  )
}

Confirm.propTypes = {
  setOpenConfirm: PropTypes.func,
  date: PropTypes.instanceOf(Date),
  expenses: PropTypes.array,
  setType: PropTypes.func,
  TYPES: PropTypes.object,
}
