import React, { Fragment, useState } from 'react'
import AddItemFormik from './AddItemForm'
import DisplayTable from './DisplayTable'
import CreateAdjustmentFormik from './CreateAdjustmentForm'
import _ from 'lodash'
import { useMutation } from '@apollo/react-hooks'
import { INVENTORY_ADJUSTMENT_MUTATION } from '../../../mutations'
import { ShowFlashMessage } from 'utils'
import PropTypes from 'prop-types'

export default function CreateAdjustment({ setOpen }) {
  const [items, setItems] = useState([])

  const [adjustInventory] = useMutation(INVENTORY_ADJUSTMENT_MUTATION, {
    update: (cache, { data }) => {
      setOpen(false)
      ShowFlashMessage(
        cache,
        `Successfully adjusted items(s) with refCode: ${data.adjustInventory.refCode}`
      )
    },
    refetchQueries: ['ListInventoryAdjustment'],
  })

  const handleAddItem = item => {
    let itemExist = _.find(items, data => {
      return data.item === item.item
    })

    if (itemExist) {
      let index = _.findIndex(items, { id: item.id })
      itemExist.quantity += item.quantity
      items.splice(index, 1)
      item = itemExist
    }
    setItems(items.concat(item))
  }

  const deleteItem = id => {
    let index = _.findIndex(items, { id: id })
    items.splice(index, 1)
    setItems(items.concat())
  }

  return (
    <Fragment>
      <AddItemFormik items={items} setItems={handleAddItem}></AddItemFormik>
      <DisplayTable itemValues={items} deleteItem={deleteItem}></DisplayTable>
      <CreateAdjustmentFormik
        items={items}
        adjustInventory={adjustInventory}
      ></CreateAdjustmentFormik>
    </Fragment>
  )
}

CreateAdjustment.propTypes = {
  setOpen: PropTypes.func.isRequired,
}
