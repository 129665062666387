/* eslint-disable react/display-name */
import React from 'react'
import { Query } from '@apollo/react-components'
import { GET_PURCHASE_ITEMS } from '../../queries/queries'
import PropType from 'prop-types'
import moment from 'moment-timezone'
import Currency from 'react-currency-formatter'
import Loading from '../../../Reports/common/Loading'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core'
import { a11yProps, TabPanel, useStyles } from '../OutDialog/OutDialog.js'

// eslint-disable-next-line react/prop-types
export default function PurchaseOrderRecordsTable({ itemId, isMobile }) {
  const classes = useStyles()
  const [value, setValue] = React.useState('one')

  function handleChange(event, newValue) {
    setValue(newValue)
  }
  return (
    <React.Fragment>
      {itemId && (
        <Query
          query={GET_PURCHASE_ITEMS}
          variables={{ itemId: itemId }}
          fetchPolicy="cache-and-network"
        >
          {({ error, loading, data: { getPurchaseItems } }) => {
            if (error) return `Error while fetching data`
            if (loading) return <Loading />

            if (!getPurchaseItems) return null

            let {
              data: items,
              //pageInfo: { page },
            } = getPurchaseItems

            return (
              <>
                <AppBar
                  position={isMobile ? 'fixed' : 'static'}
                  color="inherit"
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="wrapped label tabs example"
                  >
                    <Tab
                      value="one"
                      label="Purchase Order Records"
                      wrapped
                      {...a11yProps('one')}
                    />
                  </Tabs>
                </AppBar>
                <TabPanel
                  value={value}
                  index="one"
                  className={classes.fixedSize}
                >
                  <Table style={{ overflow: 'auto' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Item Code</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Purchase Order #</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Total Cost</TableCell>
                        <TableCell>Order Date</TableCell>
                        <TableCell>Date Received</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items &&
                        items.map((item, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{item.item.itemCode}</TableCell>
                              <TableCell>{item.item.description}</TableCell>
                              <TableCell>{item.purchaseOrderNum}</TableCell>
                              <TableCell>{item.quantity}</TableCell>
                              <TableCell>
                                <Currency quantity={item.cost} currency="PHP" />
                              </TableCell>
                              <TableCell>
                                {moment(item.created_at).format('DD MMM, YYYY')}
                              </TableCell>
                              <TableCell>
                                {moment(item.updated_at).format('DD MMM, YYYY')}
                              </TableCell>
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TabPanel>
              </>
            )
          }}
        </Query>
      )}
    </React.Fragment>
  )
}

PurchaseOrderRecordsTable.PropType = {
  itemId: PropType.number.isRequired,
}
