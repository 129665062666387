import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import DeleteForm from './DeleteForm'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/react-hooks'

import { VOID_INVOICE_MUTATION } from './../../../mutation'
import { ShowFlashMessage } from 'utils'

export default function DeleteInvoice({ open, handleClose, invoiceId }) {
  const [voidInvoice] = useMutation(VOID_INVOICE_MUTATION, {
    update: cache => {
      ShowFlashMessage(cache, `Invoice successfuly voided`)
    },
  })
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Remove This INVOICE ?'}
        </DialogTitle>
        <DialogContent>
          <DeleteForm
            handleClose={handleClose}
            invoiceId={invoiceId}
            onSubmit={voidInvoice}
          ></DeleteForm>
        </DialogContent>
      </Dialog>
    </div>
  )
}

DeleteInvoice.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  invoiceId: PropTypes.object,
}
