import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { LIST_MATERIAL_MASTER } from '../../queries/queries'
import TablePaginationActions from '../../common/TablePaginationActions/TablePaginationActions'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
} from '@material-ui/core'
import { Query } from '@apollo/react-components'
import Currency from 'react-currency-formatter'
import CreateModal from '../../common/CustomModal'
import { materialMasterTableStyle } from '../../assets/styles'
import PurchaseOrderRecordsTable from './PurchaseOrderRecordsTable'
import OutDialog from '../OutDialog'
import EditIcon from '@material-ui/icons/Edit'
import Loading from '../../../Reports/common/Loading'

// Main Component
export default function MaterialMasterTable({
  date,
  search,
  setDialog,
  isMobile,
}) {
  const classes = materialMasterTableStyle()
  const [state, setState] = React.useState({
    open: false,
    page: 0,
    limit: 10,
    itemId: null,
  })

  const [dialog, setOpenDialog] = React.useState({
    inDialog: false,
    outDialog: false,
    itemId: null,
  })

  const handleEditClick = row => {
    setDialog({ open: true, type: 'EDIT', data: row })
  }

  // Pagination Functions
  const handleChangePage = (event, page) => {
    setState({ ...state, page: page })
  }

  const handleChangeRowsPerPage = event => {
    setState({ ...state, limit: parseInt(event.target.value) })
  }

  return (
    <Fragment>
      <Paper style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <Query
          query={LIST_MATERIAL_MASTER}
          variables={{
            number: state.page + 1,
            limit: state.limit,
            date: date,
            filter: search,
          }}
        >
          {({ error, loading, data: { listMaterialMaster } }) => {
            if (error) return 'error'
            if (loading || !listMaterialMaster) return <Loading />
            let {
              data: materialMasterItems,
              pageInfo: { page, perPage, total },
            } = listMaterialMaster

            return (
              <Fragment>
                <div style={{ overflowX: 'auto', minWidth: '300px' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.tableCell} align="left">
                          Item Code
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          Description
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          Material Typefg
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          Unit of Measure
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          Unit Cost
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          Selling Price
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          Opening Stock
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          IN
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          OUT
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          Closing Stock
                        </TableCell>
                        {/* {type === 'EDIT' && ( */}
                        <TableCell className={classes.tableCell} align="left">
                          Edit Item
                        </TableCell>
                        {/* )} */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {materialMasterItems.length === 0 && (
                        <TableRow>
                          <TableCell
                            colSpan={10}
                            style={{
                              textAlign: 'center',
                              padding: '20px',
                            }}
                          >
                            No results found.
                          </TableCell>
                        </TableRow>
                      )}
                      {materialMasterItems.map(row => (
                        <TableRow
                          hover
                          key={row.id}
                          onDoubleClick={() => handleEditClick(row)}
                        >
                          <TableCell align="left">{row.itemCode}</TableCell>
                          <TableCell align="left">{row.description}</TableCell>
                          <TableCell align="left">{row.materialType}</TableCell>
                          <TableCell align="left">
                            {row.unitOfMeasure}
                          </TableCell>
                          <TableCell align="right">
                            <Currency quantity={row.unitCost} currency="PHP" />
                          </TableCell>
                          <TableCell align="right">
                            <Currency
                              quantity={row.sellingPrice}
                              currency="PHP"
                            />
                          </TableCell>
                          <TableCell align="right">
                            {row.openingStock}
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() =>
                                setOpenDialog({
                                  ...dialog,
                                  inDialog: true,
                                  itemId: row.id,
                                })
                              }
                            >
                              {row.in}
                            </Button>
                          </TableCell>
                          <TableCell align="left">
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() =>
                                setOpenDialog({
                                  ...dialog,
                                  outDialog: true,
                                  itemId: row.id,
                                })
                              }
                            >
                              {row.out}
                            </Button>
                          </TableCell>
                          <TableCell align="right">
                            {row.closingStock}
                          </TableCell>

                          {/* {type === 'EDIT' && ( */}
                          <TableCell align="left" className={classes.editCell}>
                            <EditIcon
                              onClick={() =>
                                setDialog({
                                  open: true,
                                  type: 'EDIT',
                                  data: row,
                                })
                              }
                            />
                          </TableCell>
                          {/* )} */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                <div style={{ overflowX: 'auto', width: '100%' }}>
                  <TablePagination
                    component="div"
                    align="right"
                    className={classes.paginationStyle}
                    rowsPerPageOptions={[5, 10, 20]}
                    count={parseInt(total)}
                    rowsPerPage={parseInt(perPage)}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </div>
              </Fragment>
            )
          }}
        </Query>
      </Paper>

      {/* Purchase Order Modal Component */}
      <CreateModal
        open={dialog.inDialog}
        closeDialog={() => setOpenDialog({ ...dialog, inDialog: false })}
        FormContent={<PurchaseOrderRecordsTable itemId={dialog.itemId} />}
      />
      {/* Sales Invoice Records Modal Component */}
      <CreateModal
        open={dialog.outDialog}
        closeDialog={() => setOpenDialog({ ...dialog, outDialog: false })}
        FormContent={<OutDialog itemId={dialog.itemId} isMobile={isMobile} />}
      />
    </Fragment>
  )
}

MaterialMasterTable.propTypes = {
  date: PropTypes.instanceOf(Date),
  itemData: PropTypes.object,
  open: PropTypes.bool,
  openDialog: PropTypes.func,
  closeDialog: PropTypes.func,
  search: PropTypes.string,
  setDialog: PropTypes.func,
  isMobile: PropTypes.bool,
}
