import React from 'react'
import { TextField, Button, Container } from '@material-ui/core'
import PropTypes from 'prop-types'

export default function AddExpensesForm({ addExpenses, closeDialog }) {
  let [values, setValues] = React.useState({
    description: '',
    amount: 0,
  })

  const handleSubmit = () => {
    if (values.description !== '' && values.amount !== 0) addExpenses(values)

    closeDialog()
  }

  return (
    <Container maxWidth="xl" style={{ padding: '2px', dispay: 'block' }}>
      <TextField
        fullWidth
        name="description"
        style={{ margin: '5px' }}
        label="Label"
        onChange={e => setValues({ ...values, description: e.target.value })}
      />
      <TextField
        fullWidth
        type="number"
        name="amount"
        onChange={e =>
          setValues({ ...values, amount: parseFloat(e.target.value) })
        }
        style={{ margin: '5px' }}
        label="Amount"
      />

      <Button
        fullWidth
        type="submit"
        variant="outlined"
        color="primary"
        onClick={() => handleSubmit()}
        style={{ margin: '5px' }}
      >
        Confirm
      </Button>
    </Container>
  )
}

AddExpensesForm.propTypes = {
  addExpenses: PropTypes.func,
  closeDialog: PropTypes.func,
}
