import React from 'react'
import { Form, withFormik } from 'formik'
import { TextField, MenuItem, Grid, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@material-ui/styles'
import { theme } from '../../../assets/styles'
import { formStyle } from './style'
import * as Yup from 'yup'

const CreateAdjustmentForm = ({ values, handleChange, errors, touched }) => {
  const classes = formStyle()

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Form>
          <Grid container spacing={1} style={{ overflowX: 'hidden' }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                name="category"
                select
                label="Category"
                fullWidth
                className={classes.select}
                margin="normal"
                onChange={handleChange}
                value={values.category}
                error={errors.category && touched.category}
              >
                <MenuItem value="TRANSFERRED">TRANSFERRED</MenuItem>
                <MenuItem value="WRITTEN_OFF">WRITTEN_OFF</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <TextField
                name="reason"
                type="text"
                label="Reason"
                margin="normal"
                fullWidth
                className={classes.textField}
                onChange={handleChange}
                value={values.reason}
                error={errors.reason && touched.category}
              ></TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Button
                fullWidth
                type="submit"
                variant="outlined"
                color="primary"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Form>
      </ThemeProvider>
    </div>
  )
}
CreateAdjustmentForm.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
}

const CreateAdjustmentFormik = withFormik({
  mapPropsToValues({ items }) {
    return {
      category: '',
      reason: '',
      items: items || [],
    }
  },
  validationSchema: Yup.object().shape({
    category: Yup.string().required('Please specify the category'),
    reason: Yup.string().required('Please specify the reason'),
  }),
  enableReinitialize: true,

  handleSubmit(variables, { props }) {
    let newItems = variables.items.map(item => {
      return { id: item.id, quantity: item.quantity }
    })

    variables.items = newItems

    props.adjustInventory({ variables })
  },
})(CreateAdjustmentForm)

export default CreateAdjustmentFormik
