import React from 'react'
import { LoadingStyle } from '../assets/styles'
import loadingImage from '../assets/loader.gif'

export default function Loading() {
  const classes = LoadingStyle()

  return (
    <div className={classes.loadingContainer}>
      <img src={loadingImage} alt="loading" />
    </div>
  )
}
