/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react'
import { Form, withFormik, FieldArray } from 'formik'
import { TextField, Button } from '@material-ui/core'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { addItemFormStyles, theme } from '../../../../assets/styles'
import { ThemeProvider } from '@material-ui/styles'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const AccountsAddForm = ({
  values,
  handleChange,
  errors,
  handleBlur,
  touched,
  type,
  setFieldValue,
}) => {
  const classes = addItemFormStyles()
  const roles = ['ADMIN', 'PURCHASER', 'SALES_AGENT', 'AUDITOR']

  return (
    <ThemeProvider theme={theme}>
      <Form className={classes.container} autoComplete="off">
        <TextField
          label="First Name"
          name="firstName"
          id="user-firstName"
          onChange={handleChange}
          className={classes.textField}
          value={values.firstName}
          margin="normal"
          onBlur={handleBlur}
          error={errors.firstName && touched.firstName}
          helperText={errors.firstName}
        />
        <TextField
          label="Last Name"
          name="lastName"
          id="user-lastName"
          onChange={handleChange}
          className={classes.textField}
          value={values.lastName}
          margin="normal"
          onBlur={handleBlur}
          error={errors.lastName && touched.lastName}
          helperText={errors.lastName}
        />

        <TextField
          label="Username"
          name="username"
          id="user-username"
          onChange={handleChange}
          className={classes.textField}
          value={values.username}
          margin="normal"
          onBlur={handleBlur}
          error={errors.username && touched.username}
          helperText={errors.username}
        />

        {type === 'CREATE' && (
          <TextField
            label="Password"
            name="password"
            id="user-password"
            type="password"
            onChange={handleChange}
            className={classes.textField}
            value={values.password}
            margin="normal"
            onBlur={handleBlur}
            error={errors.password && touched.password}
            helperText={errors.password}
          />
        )}

        <TextField
          label="Email"
          name="email"
          id="user-email"
          onChange={handleChange}
          className={classes.descriptionField}
          value={values.email}
          margin="normal"
          onBlur={handleBlur}
          error={errors.email && touched.email}
          helperText={errors.email}
        />

        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">Select Roles</FormLabel>
          <FormGroup>
            <FieldArray
              name="role"
              render={arrayHelpers =>
                roles.map((role, key) => {
                  return (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          onChange={e => {
                            if (role === 'ADMIN' && e.target.checked) {
                              setFieldValue('role', ['ADMIN'])
                            } else {
                              e.target.checked
                                ? arrayHelpers.push(role)
                                : arrayHelpers.remove(
                                    values.role.findIndex(
                                      valueRole => valueRole === role
                                    )
                                  )
                            }
                          }}
                          checked={values.role.includes(role)}
                          disabled={
                            values.role.includes('ADMIN') && role !== 'ADMIN'
                          }
                        />
                      }
                      label={role}
                    />
                  )
                })
              }
            />
          </FormGroup>
        </FormControl>

        <Button
          variant="outlined"
          type="submit"
          color="primary"
          fullWidth
          style={{ marginBottom: '20px', color: '#2d3e50', marginTop: '20px' }}
        >
          Submit
        </Button>
      </Form>
    </ThemeProvider>
  )
}

AccountsAddForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  type: PropTypes.any,
  setFieldValue: PropTypes.func,
}

const AccountsAddWithFormik = withFormik({
  mapPropsToValues({ user }) {
    return {
      id: user && user.id,
      firstName: (user && user.firstName) || '',
      lastName: (user && user.lastName) || '',
      email: (user && user.email) || '',
      role: (user && user.role) || [],
      password: (user && user.password) || '',
      username: (user && user.username) || '',
    }
  },

  // Validation
  validationSchema: props => {
    let validation = {
      firstName: Yup.string().required('Please enter First Name'),
      lastName: Yup.string().required('Please enter Last Name'),
      email: Yup.string().required('Please enter Email'),
      role: Yup.string().required('Please specify Role'),
      password: Yup.string().required('Please enter Password'),
      username: Yup.string().required('Please enter username'),
    }
    if (props.type === 'EDIT') {
      delete validation.password
    }

    return Yup.object().shape(validation)
  },
  enableReinitialize: true,

  handleSubmit(variables, { props }) {
    props.onSubmit({ variables })
  },
})(AccountsAddForm)

export default AccountsAddWithFormik
