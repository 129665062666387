import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core'
import { theme } from '../../InventoryAdjustment/assets/styles'

export const customTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#2d3e50',
    },
  },
})

export const materialMasterStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  addButton: {
    paddingTop: '10px',
    paddingBottom: '10px',
    cursor: 'pointer',
  },
  toolbar: {
    width: '100%',
    marginBottom: '20px',
    padding: '20px',
    display: 'inline-block',
  },
  searchInput: {
    float: 'left',
  },
}))

export const materialTableStyles = makeStyles(theme => ({
  titleStyle: {
    fontSize: '20px',
  },
}))

export const materialDatePickerStyles = makeStyles(theme => ({
  calendarBigText: {
    color: '#2d3e50',
  },
  calendarSmallText: {
    color: '#d3e50',
  },
  calendarBackground: {
    backgroundColor: '#FFFFFF',
  },
}))

export const customCalenderTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        color: '#FFFFFF',
        backgroundColor: '#2d3e50',
      },
    },
    MuiIconButton: {
      root: {
        color: '#FFFFFF',
      },
    },
    MuiInputBase: {
      input: {
        [theme.breakpoints.down('sm')]: {
          display: 'none',
        },
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
    },

    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
      toolbarBtnSelected: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: '#2d3e50',
      },
    },
    MuiPickersMonth: {
      monthSelected: {
        color: '#2d3e50',
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: '#2d3e50',
      },
    },
  },
})

export const materialMasterTableStyle = makeStyles(theme => ({
  tableHeader: {
    marginLeft: '20px',
    width: 'max-content',
    fontWeight: '1',
    datePicker: {
      marginLeft: '30px',
    },
  },
  tableCell: {
    color: '#2d3e50',
  },
  tableCellHeaderText: {
    backgroundColor: '#FFFFFF',
  },
  editCell: {
    cursor: 'pointer',
  },
  paginationStyle: {
    [theme.breakpoints.down('xs')]: {
      width: 'auto',
      float: 'left',
    },
  },
}))
