import React from 'react'
import { SalesInvoiceToolbarStyles } from './assets/styles'
import { Container, Grid } from '@material-ui/core'
import Toolbar from './ToolBar/Toolbar'
import TableSales from './TableSales'
export default function IncomeSales() {
  const classes = SalesInvoiceToolbarStyles()
  let [selectedDate, handleDateChange] = React.useState(new Date())
  //   let [searchString, setSearch] = React.useState('')
  let [dialog, setDialog] = React.useState({
    open: false,
    type: null,
    data: null,
  })
  return (
    <div>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Toolbar
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
              setDialog={setDialog}
              type={dialog.type}
              title="Sales"
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <TableSales />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
