import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import * as Yup from 'yup'
import { withFormik, Form } from 'formik'
import PropTypes from 'prop-types'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Button } from '@material-ui/core'
function PasswordForm(props) {
  let { errors, handleBlur, touched, values, handleChange, handleClose } = props
  const [state, setState] = React.useState({
    showOldPassword: false,
    showNewPassword: false,
    showReTypePassword: false,
  })

  const handleClickShowOldPassword = () => {
    setState({ ...state, showOldPassword: !state.showOldPassword })
  }
  const handleClickShowNewPassword = () => {
    setState({ ...state, showNewPassword: !state.showNewPassword })
  }

  const handleClickShowPassword = () => {
    setState({ ...state, showReTypePassword: !state.showReTypePassword })
  }

  return (
    <Form>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="outlined-adornment-oldpassword"
            variant="outlined"
            type={state.showOldPassword ? 'text' : 'password'}
            label="Old Password"
            name="oldPassword"
            value={values.oldPassword}
            onChange={handleChange}
            error={errors.oldPassword && touched.oldPassword}
            helperText={touched.oldPassword && errors.oldPassword}
            onBlur={handleBlur}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowOldPassword}
                  >
                    {state.showOldPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="outlined-adornment-newpassword"
            variant="outlined"
            type={state.showNewPassword ? 'text' : 'password'}
            label="New Password"
            name="newPassword"
            value={values.newPassword}
            onChange={handleChange}
            error={errors.newpassword && touched.newpassword}
            helperText={touched.newpassword && errors.newpassword}
            onBlur={handleBlur}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={handleClickShowNewPassword}>
                    {state.showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-adornment-retypepassword"
            variant="outlined"
            type={state.showReTypePassword ? 'text' : 'password'}
            label="Re-Type Password"
            name="reTypePassword"
            value={values.reTypePassword}
            onChange={handleChange}
            error={errors.reTypePassword && touched.reTypePassword}
            helperText={
              touched.reTypePassword && errors.retypereTypePasswordpassword
            }
            onBlur={handleBlur}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    aria-label="Toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {state.showReTypePassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <br />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={8} lg={8} />

        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Button
            onClick={handleClose}
            variant="contained"
            color="primary"
            fullWidth
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </Grid>
      </Grid>
    </Form>
  )
}
PasswordForm.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
}
const Formik = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ oldPassword, newPassword, reTypePassword }) {
    return {
      oldPassword: oldPassword || '',
      newPassword: newPassword || '',
      reTypePassword: reTypePassword || '',
    }
  },
  validationSchema: Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string().required('New Password is required'),
    reTypePassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null])
      .required('Password confirm is required'),
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit({ variables: values })
  },
})(PasswordForm)

export default Formik
