import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import { amber, green } from '@material-ui/core/colors'
import IconButton from '@material-ui/core/IconButton'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import WarningIcon from '@material-ui/icons/Warning'
import { makeStyles } from '@material-ui/core/styles'
import { Query } from '@apollo/react-components'
import { GET_FLASH_MESSAGE } from 'queries'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const useStyles1 = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function AppSnackBar(props) {
  const classes = useStyles1()
  const { className, ...other } = props

  return (
    <React.Fragment>
      <Query query={GET_FLASH_MESSAGE}>
        {({
          data: {
            flashMessage: { open, message, variant },
          },
          client,
        }) => {
          const Icon = variantIcon[variant]
          const onClose = () => {
            let data = {
              flashMessage: {
                __typename: 'FlashMessage',
                open: false,
                message: '',
                variant: 'success',
              },
            }
            client.writeQuery({ query: GET_FLASH_MESSAGE, data })
          }
          return (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={open}
              autoHideDuration={6000}
              onClose={onClose}
            >
              <SnackbarContent
                className={clsx(classes[variant], className)}
                aria-describedby="client-snackbar"
                message={
                  <span id="client-snackbar" className={classes.message}>
                    <Icon className={clsx(classes.icon, classes.iconVariant)} />
                    {message}
                  </span>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={onClose}
                  >
                    <CloseIcon className={classes.icon} />
                  </IconButton>,
                ]}
                {...other}
              />
            </Snackbar>
          )
        }}
      </Query>
    </React.Fragment>
  )
}

AppSnackBar.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
}
