import React from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider } from '@material-ui/styles'
import { customCalenderTheme } from './../../assets/styles'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'

function YearMonthPicker({ selectedDate, handleDateChange }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ThemeProvider theme={customCalenderTheme}>
        <DatePicker
          autoOk
          disableFuture
          value={selectedDate}
          onChange={handleDateChange}
          style={{ marginTop: '16px' }}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

YearMonthPicker.propTypes = {
  selectedDate: PropTypes.any.isRequired,
  handleDateChange: PropTypes.func.isRequired,
}

export default YearMonthPicker
