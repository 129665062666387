/* eslint-disable no-console */
import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink, Observable } from 'apollo-link'
import { ShowFlashMessage } from './utils'

const cache = new InMemoryCache()

const request = async (operation) => {
  const token = await localStorage.getItem('accessToken')
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })
}
const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle
      // eslint-disable-next-line no-undef
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation }) => {
      let { cache } = operation.getContext()
      if (networkError) {
        // eslint-disable-next-line no-undef
        console.log(networkError, 'network Error')
        ShowFlashMessage(cache, networkError.message, 'error')
      }
      if (graphQLErrors) {
        // eslint-disable-next-line no-undef
        ShowFlashMessage(cache, graphQLErrors[0].message, 'error')
      }
    }),
    requestLink,
    new HttpLink({
      uri: 'https://api.anyastore.com/graphql', // Add your GraphQL URL here
      // eslint-disable-next-line no-undef
      credentials: 'include',
    }),
  ]),
  cache,
})

cache.writeData({
  data: {
    flashMessage: {
      __typename: 'FlashMessage',
      open: false,
      message: '',
      variant: 'success',
    },
  },
})

export default client
