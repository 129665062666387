import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import { navigate } from '@reach/router'

export default function AccountMenu({ anchorEl, id, open, onClose }) {
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={id}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={onClose}
    >
      <MenuItem
        onClick={() => {
          navigate('/profile')
        }}
      >
        Profile
      </MenuItem>
      <MenuItem
        onClick={() => {
          localStorage.removeItem('accessToken')
          navigate('/login')
        }}
      >
        Log Out
      </MenuItem>
    </Menu>
  )
}

AccountMenu.propTypes = {
  anchorEl: PropTypes.object,
  id: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}
