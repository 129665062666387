import gql from 'graphql-tag'

export const GET_TENTATIVE_REPORT = gql`
  query GetTentativeReport(
    $number: Int
    $limit: Int
    $filter: String
    $date: String
  ) {
    getTentativeReport(
      page: { number: $number, limit: $limit, filter: $filter, date: $date }
    ) {
      cogsPageInfo {
        total
        page
        perPage
        lastPage
      }
      cogs
      revenue
      grossProfit
      cogsItems {
        id
        itemCode
        description
        quantity
        cost
      }
      expenses {
        total
        items {
          id
          description
          amount
        }
      }
    }
  }
`

export const LIST_REPORTS_QUERY = gql`
  query ListReports($number: Int, $limit: Int, $filter: String, $date: String) {
    listPlReports(
      page: { number: $number, limit: $limit, filter: $filter, date: $date }
    ) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        plNum
        created_at
        cogsItems {
          id
          itemCode
          description
          quantity
          cost
        }
        cogs
        revenue
        netProfit
        expenses {
          total
          items {
            description
            amount
          }
        }
      }
    }
  }
`
