import React, { useState } from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { useQuery } from '@apollo/react-hooks'
import PropTypes from 'prop-types'
import Loading from 'components/Dashboard/components/Reports/common/Loading'
import { GET_ITEM_INVENTORY_ADJUSTMENT } from '../../queries/queries'

export default function WriteOffTable({ itemId }) {
  // Default state
  const [state] = useState({
    open: false,
    items: [],
    limit: 20,
    perPage: 5,
    page: 1,
  })

  // Query
  const {
    loading,
    error,
    data: { getItemInventoryAdjustment },
  } = useQuery(GET_ITEM_INVENTORY_ADJUSTMENT, {
    variables: {
      page: {
        number: state.page,
        limit: state.limit,
      },
      itemId: itemId,
    },
  })

  if (loading) return <Loading></Loading>
  if (error) return 'Error fetching data.'

  let {
    data: items,
    // pageInfo: { page, total, perPage },
  } = getItemInventoryAdjustment

  return (
    <div>
      <Table style={{ overflowX: 'auto' }}>
        <TableHead>
          <TableRow>
            <TableCell>Ref. Code</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Reason</TableCell>
            <TableCell>Created at</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.length === 0 && (
            <TableRow>
              <TableCell
                align="center"
                colSpan={4}
                style={{ padding: '40px', width: '845px !important' }}
              >
                No Data
              </TableCell>
            </TableRow>
          )}
          {items &&
            items.map((item, key) => {
              return (
                <TableRow key={key}>
                  <TableCell align="center">{item.refCode}</TableCell>
                  <TableCell align="center">{item.category}</TableCell>
                  <TableCell align="center">{item.reason}</TableCell>
                  <TableCell align="center">{item.created_at}</TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </div>
  )
}

WriteOffTable.propTypes = {
  itemId: PropTypes.number,
}
