import React, { Fragment, useState } from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  makeStyles,
  TablePagination,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import TablePaginationActions from './TablePaginationActions'
import { theme } from '../../assets/styles'
import { ThemeProvider } from '@material-ui/styles'
import Modal from '../../common/Modal'
import ItemsTable from './ItemsTable'
import { useQuery } from '@apollo/react-hooks'
import { LIST_INVENTORY_ADJUSTMENT_QUERY } from '../../queries'
import Loading from 'components/Dashboard/components/Reports/common/Loading'

const tableStyles = makeStyles({
  nodata: { padding: '20px 0px' },
})

export default function InventoryAdjustmentTable({
  searchString,
  category,
  date,
}) {
  const classes = tableStyles()

  const [state, setState] = useState({
    open: false,
    items: [],
    limit: 20,
    perPage: 5,
    page: 1,
  })

  const {
    loading,
    error,
    data: { listInventoryAdjustment },
  } = useQuery(LIST_INVENTORY_ADJUSTMENT_QUERY, {
    variables: {
      page: {
        number: state.page,
        limit: state.limit,
        filter: searchString,
        date: date,
      },
      category: category === '' ? null : category,
    },
  })

  if (loading) return <Loading></Loading>
  if (error) return 'Error'

  let {
    data: list,
    pageInfo: { page, total, perPage },
  } = listInventoryAdjustment

  // Pagination Functions
  const handleChangePage = (event, page) => {
    setState({ ...state, page: page })
  }

  const handleChangeRowsPerPage = event => {
    setState({ ...state, limit: parseInt(event.target.value) })
  }

  const handleRowClick = items => {
    setState({ ...state, open: true, items: items })
  }

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <div style={{ overflowX: 'auto', width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Ref. Code</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Reason</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>Created at</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!list && (
                <TableRow>
                  <TableCell
                    colSpan={5}
                    className={classes.nodata}
                    align="center"
                  >
                    No Data Yet
                  </TableCell>
                </TableRow>
              )}
              {list &&
                list.map((item, key) => {
                  return (
                    <TableRow
                      key={key}
                      hover
                      onClick={() => handleRowClick(item.items)}
                    >
                      <TableCell>{item.refCode}</TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.reason}</TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>
                        {moment(item.created_at).format('MMM DD, YYYY')}
                      </TableCell>
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          align="right"
          rowsPerPageOptions={[5, 10, 20]}
          count={parseInt(total)}
          rowsPerPage={parseInt(perPage)}
          page={parseInt(page) - 1}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
          component={'div'}
        />
        <Modal
          open={state.open}
          title={'Items'}
          handleClose={() => setState({ ...state, open: false })}
          FormContent={<ItemsTable items={state.items}></ItemsTable>}
        ></Modal>
      </ThemeProvider>
    </Fragment>
  )
}

InventoryAdjustmentTable.propTypes = {
  data: PropTypes.object,
  searchString: PropTypes.string,
  category: PropTypes.string,
  date: PropTypes.instanceOf(Date),
}
