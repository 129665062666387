import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function Modal({ open, handleClose, title, FormContent }) {
  const theme = useTheme()
  const fulllScreen = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen={fulllScreen}
      maxWidth="lg"
      scroll="paper"
    >
      {title && (
        <DialogTitle style={{ backgroundColor: '#2d3e50', color: '#FFFFFF' }}>
          {title}
        </DialogTitle>
      )}
      <DialogContent>{FormContent}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

Modal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  FormContent: PropTypes.any,
}
