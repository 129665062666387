import React from 'react'
import {
  Dialog,
  DialogTitle,
  useMediaQuery,
  useTheme,
  DialogActions,
  Button,
} from '@material-ui/core'
import PropTypes from 'prop-types'

export default function CreateModal({ open, closeDialog, FormContent, title }) {
  const theme = useTheme()
  const fulllScreen = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      style={{ width: 'auto' }}
      maxWidth="md"
      scroll="paper"
      fullScreen={fulllScreen}
    >
      {title && (
        <DialogTitle style={{ backgroundColor: '#2d3e50', color: '#FFFFFF' }}>
          {title}
        </DialogTitle>
      )}

      {/* <DialogContent>{FormContent}</DialogContent> */}
      {FormContent}

      {fulllScreen && (
        <DialogActions
          style={{ position: 'fixed', bottom: '10px', right: '10px' }}
        >
          <Button onClick={closeDialog} variant="text" color="primary">
            Close
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

CreateModal.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  closeDialog: PropTypes.func,
  FormContent: PropTypes.any,
}
