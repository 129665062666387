import React, { Fragment } from 'react'
import {
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core'
import { materialMasterTableStyle } from '../../../assets/styles'
import PropTypes from 'prop-types'

export default function CostOfGoodsSold({ data, totalCogs }) {
  const classes = materialMasterTableStyle()

  // let [totalCost, setTotalCost] = useState()
  return (
    <Fragment>
      <Table style={{ minWidth: '600px' }}>
        <TableHead>
          <TableCell className={classes.tableCell}>Item Code</TableCell>
          <TableCell className={classes.tableCell}>Item Description</TableCell>
          <TableCell className={classes.tableCell}>Quantity</TableCell>
          <TableCell className={classes.tableCell}>Cost</TableCell>
        </TableHead>
        <TableBody>
          {data &&
            data.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.itemCode}</TableCell>
                  <TableCell>{item.description}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.cost}</TableCell>
                </TableRow>
              )
            })}
          {!data && (
            <TableRow>
              <TableCell colSpan={4} align="center" style={{ height: '100px' }}>
                No Data
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell colSpan={3}>Total Cost</TableCell>
            <TableCell colSpan={1}>{totalCogs}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Fragment>
  )
}

CostOfGoodsSold.propTypes = {
  data: PropTypes.array,
  totalCogs: PropTypes.number,
}
