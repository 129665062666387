import gql from 'graphql-tag'

export const GENERATE_REPORT_MUTATION = gql`
  mutation GenerateReport($date: String) {
    generateReport(date: $date) {
      plNum
      cogsItems {
        id
        itemCode
        description
        quantity
        cost
      }
      cogs
      revenue
      netProfit
      expenses {
        total
        items {
          description
          amount
        }
      }
    }
  }
`

export const CREATE_EXPENSES_MUTATION = gql`
  mutation($description: String!, $amount: Float!, $accountingPeriod: String!) {
    createExpense(
      input: {
        description: $description
        amount: $amount
        accountingPeriod: $accountingPeriod
      }
    ) {
      description
      amount
    }
  }
`
export const UPDATE_EXPENSES_MUTATION = gql`
  mutation UpdateExpense($id: Int!, $description: String, $amount: Float!) {
    updateExpense(
      input: { id: $id, description: $description, amount: $amount }
    ) {
      id
      description
      amount
      void
    }
  }
`

export const VOID_EXPENSE_MUTATION = gql`
  mutation VoidExpense($id: Int!) {
    voidExpense(id: $id) {
      id
      description
      amount
      void
    }
  }
`
