import React from 'react'
import PropTypes from 'prop-types'
import PasswordForm from './PasswordForm'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { Mutation } from '@apollo/react-components'
import { ShowFlashMessage } from 'utils'
import { UPDATE_PASSWORD_MUTATION } from '../mutation'
export default function PasswordDialog({ handleClose, open }) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-Create"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-Create">{'Change Password'}</DialogTitle>
        <DialogContent>
          <Mutation
            mutation={UPDATE_PASSWORD_MUTATION}
            update={cache => {
              ShowFlashMessage(cache, `Password Successfuly Upadated`)
            }}
            onCompleted={() => {
              handleClose()
            }}
          >
            {(mutate, { loading }) => {
              return (
                <PasswordForm
                  onSubmit={mutate}
                  loading={loading}
                  handleClose={handleClose}
                />
              )
            }}
          </Mutation>
        </DialogContent>
      </Dialog>
    </div>
  )
}
PasswordDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
