import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import { TableHead } from '@material-ui/core'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import { CircularProgress, Button } from '@material-ui/core'
import _ from 'lodash'
import { LIST_SALE_INVOICE_QUERY } from '../query'
import { Query } from '@apollo/react-components'
import ItemsDialog from '../components/Invoicce/Actions/ShowItemTable/ItemsDialog'
import Moment from 'react-moment'
import 'moment-timezone'
import DeleteIcon from '@material-ui/icons/Delete'
import Delete from './../Table/Actions/Delete/modal'
const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  },
}))
// const useStyles = makeStyles({
//   root: {
//     color: '#FFF',
//     backgroundColor: '#2c3e50',
//   },
// })

function TablePaginationActions(props) {
  const classes = useStyles1()

  const theme = useTheme()
  const { count, page, rowsPerPage, onChangePage } = props

  function handleFirstPageButtonClick(event) {
    onChangePage(event, 0)
  }

  function handleBackButtonClick(event) {
    onChangePage(event, page - 1)
  }

  function handleNextButtonClick(event) {
    onChangePage(event, page + 1)
  }

  function handleLastPageButtonClick(event) {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="First Page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="Previous Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Next Page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="Last Page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

export default function CustomPaginationActionsTable({ search, date }) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)
  const [itemsOpen, setItemsOpen] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  let [itemClicked, setItem] = React.useState(null)
  const [invoiceId, setInvoiceId] = React.useState(null)

  function handleChangePage(event, newPage) {
    setPage(newPage)
  }
  const handleOpenVoidInvoiceDialog = id => {
    setInvoiceId(id)
    setOpen(true)
  }
  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  return (
    <Query
      query={LIST_SALE_INVOICE_QUERY}
      variables={{
        number: page + 1,
        limit: rowsPerPage,
        filter: search,
        date: date,
      }}
      fetchPolicy={'cache-and-network'}
    >
      {({ data: { listSalesInvoice }, loading }) => {
        if (loading || !listSalesInvoice) return <CircularProgress />
        let { data, pageInfo, totalPrice } = listSalesInvoice

        let saleInvoice = _.find(listSalesInvoice.data, sales => {
          return sales.id === itemClicked
        })
        return (
          <>
            {itemClicked && saleInvoice && (
              <ItemsDialog
                open={itemsOpen}
                handleClose={() => setItemsOpen(false)}
                invoice={saleInvoice}
              />
            )}
            <Paper style={{ overflowX: 'auto' }}>
              <Table style={{ overflowX: 'auto' }}>
                <TableHead>
                  <TableRow>
                    <TableCell> Invoice Number</TableCell>
                    <TableCell align="right">Sold To</TableCell>
                    <TableCell align="right">Sold By</TableCell>
                    <TableCell align="right">Date Created</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.length !== 0 &&
                    data.map(row => {
                      return (
                        <TableRow key={row.id}>
                          <TableCell component="th" value={row.id} scope="row">
                            <Button
                              key={row.id}
                              style={{
                                color: row.void ? 'red' : null,
                              }}
                              onClick={() => {
                                setItem(row.id)
                                setItemsOpen(true)
                              }}
                            >
                              {row.invoiceNum}
                            </Button>
                          </TableCell>
                          <TableCell align="right">{row.soldTo}</TableCell>

                          <TableCell align="right">
                            {`${row.soldBy.firstName} ${row.soldBy.lastName}`}
                          </TableCell>

                          <TableCell align="right">
                            <Moment format="D MMM YYYY, h:mm:ss a" withTitle>
                              {row.created_at}
                            </Moment>
                          </TableCell>
                          <TableCell align="right" title="Delete Invoice">
                            {!row.void && (
                              <IconButton
                                variant="text"
                                onClick={() =>
                                  handleOpenVoidInvoiceDialog(row.id)
                                }
                              >
                                <DeleteIcon></DeleteIcon>
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    })}

                  {data.length === 0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={10}
                        style={{
                          textAlign: 'center',
                          padding: '20px',
                        }}
                      >
                        No results found.
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell component="th"> Total Sales </TableCell>
                      <TableCell align="right" />
                      <TableCell align="right" />
                      <TableCell align="right" />

                      <TableCell align="right">{totalPrice}</TableCell>
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      colSpan={0}
                      count={parseInt(pageInfo.total)}
                      rowsPerPage={parseInt(pageInfo.perPage)}
                      page={parseInt(pageInfo.page) - 1}
                      SelectProps={{
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
              <Delete
                invoiceId={invoiceId}
                open={open}
                handleClose={() => setOpen(false)}
              ></Delete>
            </Paper>
          </>
        )
      }}
    </Query>
  )
}
CustomPaginationActionsTable.propTypes = {
  search: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
}
