import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles'

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2d3e50',
    },
  },
})

export const addItemBtn = makeStyles(theme => ({
  addItemBtn: {
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
    '&:hover': {
      color: '#2d3e50!important',
      backgroundColor: '#FFFFFF!important',
      borderColor: '#2d3e50!important',
    },
  },
}))
export const addItemFormStyles = makeStyles(theme => ({
  container: {
    overflowX: 'hidden',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    paddingLeft: '30px',
    marginRight: '50px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  descriptionField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
    },
  },
  materialTypeField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1),
      width: '100%',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
}))
