import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

export default function DisplayTable({ itemValues, deleteItem }) {
  return (
    <div>
      <Container
        style={{
          minHeight: '200px',
          maxHeight: '250px',
          overflowY: 'auto',
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Item Name</TableCell>
              <TableCell align="right">Quantity</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {itemValues.map((row, key) => {
              return (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.item}
                  </TableCell>
                  <TableCell align="right">{row.quantity}</TableCell>
                  <TableCell align="right">
                    <IconButton
                      variant="outlined"
                      onClick={() => deleteItem(row.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            })}

            {itemValues.length !== 0 && (
              <TableRow>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Container>
    </div>
  )
}
DisplayTable.propTypes = {
  itemValues: PropTypes.array,
  deleteItem: PropTypes.func,
}
