import gql from 'graphql-tag'

export const DISPLAY_ACCOUNY_QUERY = gql`
  query {
    me {
      id
      firstName
      lastName
      email
      role
      username
    }
  }
`
