/* eslint-disable no-undef */
/* eslint-disable no-console */
import React from 'react'
import { Form, withFormik } from 'formik'
import { TextField, Button, MenuItem, Grid } from '@material-ui/core'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { addItemFormStyles, theme } from './styles'
import { ThemeProvider } from '@material-ui/styles'

const ItemAddForm = ({ values, handleChange, errors, handleBlur, touched }) => {
  const classes = addItemFormStyles()

  return (
    <ThemeProvider theme={theme}>
      <Form className={classes.container} autoComplete="off">
        <Grid container spacing={1} style={{ overflowX: 'hidden' }}>
          <Grid item xs={10} sm={11} md={12} lg={12}>
            <TextField
              fullWidth
              label="Item Description"
              name="description"
              id="item-description"
              onChange={handleChange}
              className={classes.descriptionField}
              value={values.description}
              margin="normal"
              onBlur={handleBlur}
              error={errors.description && touched.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={10} sm={11} md={6} lg={6}>
            <TextField
              id="item-materialType"
              name="materialType"
              select
              label="Material Type"
              className={classes.materialTypeField}
              value={values.materialType}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.materialType && touched.materialType}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              helperText={errors.materialType}
              margin="normal"
            >
              <MenuItem value="FG">FINISHED GOODS (FG)</MenuItem>
              <MenuItem value="CONSUMABLE">CONSUMABLE</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={10} sm={11} md={6} lg={6}>
            <TextField
              id="item-unitOfMeasure"
              name="unitOfMeasure"
              select
              label="Unit of Measure"
              className={classes.textField}
              value={values.unitOfMeasure}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.unitOfMeasure && touched.unitOfMeasure}
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              helperText={errors.unitOfMeasure}
              margin="normal"
            >
              <MenuItem value="pc">pc</MenuItem>
              <MenuItem value="kg">kg</MenuItem>
              <MenuItem value="ft">ft</MenuItem>
              <MenuItem value="m">m</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={10} sm={11} md={6} lg={6}>
            <TextField
              id="item-unitCost"
              name="unitCost"
              type="number"
              label="Unit Cost"
              className={classes.textField}
              value={values.unitCost}
              onChange={handleChange}
              margin="normal"
              onBlur={handleBlur}
              error={errors.unitCost && touched.unitCost}
              helperText={errors.unitCost}
            />
          </Grid>
          <Grid item xs={10} sm={11} md={6} lg={6}>
            <TextField
              id="item-sellingPrice"
              name="sellingPrice"
              type="number"
              label="Item sellingPrice"
              className={classes.textField}
              value={values.sellingPrice}
              onChange={handleChange}
              margin="normal"
              onBlur={handleBlur}
              error={errors.sellingPrice && touched.sellingPrice}
              helperText={errors.sellingPrice}
            />
          </Grid>
          <Grid item xs={10} sm={11} md={12} lg={12}>
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              fullWidth
              style={{
                marginBottom: '20px',
                color: '#2d3e50',
                marginTop: '20px',
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Form>
    </ThemeProvider>
  )
}

ItemAddForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
}

const ItemAddWithFormik = withFormik({
  mapPropsToValues({ item }) {
    return {
      id: item && item.id,
      description: (item && item.description) || '',
      materialType: (item && item.materialType) || '',
      unitOfMeasure: (item && item.unitOfMeasure) || '',
      unitCost: (item && item.unitCost) || '',
      sellingPrice: (item && item.sellingPrice) || '',
    }
  },

  // Validation
  validationSchema: Yup.object().shape({
    description: Yup.string().required('Please enter item description'),
    materialType: Yup.string().required('Please specify the type of material'),
    unitOfMeasure: Yup.string().required('Please specify the unit of measure'),
    unitCost: Yup.number()
      .positive()
      .required('Please enter the item cost per unit'),
    sellingPrice: Yup.number()
      .positive()
      .required('Please specify the selling price'),
  }),
  enableReinitialize: true,

  handleSubmit(variables, { props }) {
    props.onSubmit({ variables })
  },
})(ItemAddForm)

export default ItemAddWithFormik
