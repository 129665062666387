import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import DashboardIcon from '@material-ui/icons/Dashboard'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import SalesIcon from '@material-ui/icons/Receipt'
import AccountBox from '@material-ui/icons/AccountBox'
import BarChartIcon from '@material-ui/icons/BarChart'
import MasterListIcon from '@material-ui/icons/FileCopy'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { makeStyles } from '@material-ui/core/styles'
import { navigate } from '@reach/router'
import { Collapse, List } from '@material-ui/core'
import ListIcon from '@material-ui/icons/List'
import ProfitIcon from '@material-ui/icons/ShowChart'
import ExpensesIcon from '@material-ui/icons/MoneyOff'
import InventoryAdjustmentIcon from '@material-ui/icons/Exposure'

const iconStyles = makeStyles(theme => ({
  icon: {
    color: '#ecf0f1',
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedMobile: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '10px',
      paddingRight: '10px',
    },
  },
}))

const normalSize = 760

export const MainListItems = props => {
  const classes = iconStyles()

  const [open, setOpen] = React.useState(false)
  const [openSales, setOpenSales] = React.useState(false)

  let userData = JSON.parse(localStorage.getItem('user'))

  const navData = [
    {
      page: 'dashboard',
      navigationOnclick: () => navigate('/'),
      Icon: DashboardIcon,
      primary: 'Dashboard',
    },

    {
      page: 'accounts',
      navigationOnclick: () => navigate('/accounts'),
      Icon: AccountBox,
      primary: 'Accounts',
    },
    {
      page: 'material_master',
      navigationOnclick: () => navigate('/material-master'),
      Icon: MasterListIcon,
      primary: 'Material Master',
    },
    {
      page: 'purchase_order',
      navigationOnclick: () => navigate('/purchase-order'),
      Icon: ShoppingCartIcon,
      primary: 'Purchase Order',
    },
    {
      page: 'sales_invoice',
      navigationOnclick: () => setOpenSales(!openSales),
      Icon: SalesIcon,
      primary: 'Sales Invoice',
      collapsable: {
        in: openSales,
        onClick: () => setOpenSales(!openSales),
        subLink: [
          {
            navigation: '/sales-invoice',
            Icon: SalesIcon,
            primary: 'Invoices',
          },
          { navigation: '/sales', Icon: ListIcon, primary: 'Sales' },
        ],
      },
    },
    {
      page: 'reports',
      navigationOnclick: () => setOpen(!open),
      Icon: BarChartIcon,
      primary: 'Reports',
      collapsable: {
        in: open,
        onClick: () => setOpen(!open),
        subLink: [
          {
            navigation: '/reports/profit-and-loss',
            Icon: ProfitIcon,
            primary: 'Profit & Loss',
          },
          {
            navigation: '/reports/expenses',
            Icon: ExpensesIcon,
            primary: 'Expenses',
          },
          {
            navigation: '/reports/reports-list',
            Icon: ListIcon,
            primary: 'Reports List',
          },
        ],
      },
    },
    {
      page: 'inventory_adjustment',
      navigationOnclick: () => navigate('/inv-adjustment'),
      Icon: InventoryAdjustmentIcon,
      primary: 'Inv. Adjustment',
    },
  ]

  let newNavData = []
  let findPage

  for (const role of userData.role) {
    switch (role) {
      case 'ADMIN':
        newNavData = navData
        break
      case 'PURCHASER':
        findPage = navData.find(x => x.page === 'purchase_order')
        newNavData.push(findPage)
        break
      case 'SALES_AGENT':
        findPage = navData.find(x => x.page === 'sales_invoice')
        newNavData.push(findPage)
        break
      case 'AUDITOR':
        findPage = navData.find(x => x.page === 'reports')
        newNavData.push(findPage)
        break
      default:
        return null
    }
  }

  return (
    <div>
      <Fragment>
        {newNavData.map((data, key) => {
          const { navigationOnclick, Icon, primary, collapsable } = data

          return (
            <Fragment key={key}>
              <ListItem button onClick={navigationOnclick}>
                <ListItemIcon>
                  <Icon classes={{ root: classes.icon }} />
                </ListItemIcon>
                <ListItemText primary={primary} />
                {collapsable &&
                  (collapsable.in ? <ExpandLess /> : <ExpandMore />)}
              </ListItem>
              {collapsable && (
                <Collapse in={collapsable.in} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {collapsable.subLink.map((subLinkData, subLinkKey) => {
                      const {
                        navigation,
                        Icon: SubLinkIcon,
                        primary: subLinkPrimary,
                      } = subLinkData
                      return (
                        <ListItem
                          key={subLinkKey}
                          button
                          className={
                            // eslint-disable-next-line no-undef
                            !props.open && window.innerWidth < normalSize
                              ? classes.nestedMobile
                              : classes.nested
                          }
                          onClick={() => navigate(navigation)}
                        >
                          <ListItemIcon>
                            <SubLinkIcon
                              classes={{
                                root: classes.icon,
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary={subLinkPrimary} />
                        </ListItem>
                      )
                    })}
                  </List>
                </Collapse>
              )}
            </Fragment>
          )
        })}
      </Fragment>
    </div>
  )
}

MainListItems.propTypes = {
  open: PropTypes.bool,
}
