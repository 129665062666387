import gql from 'graphql-tag'

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $username: String!
    $password: String!
    $role: [ROLE]!
    $email: String!
    $firstName: String!
    $lastName: String!
  ) {
    createUser(
      input: {
        username: $username
        lastName: $lastName
        email: $email
        role: $role
        password: $password
        firstName: $firstName
      }
    ) {
      id
      firstName
      lastName
      email
      role
      username
    }
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $id: Int!
    $username: String
    $role: [ROLE]
    $email: String
    $firstName: String
    $lastName: String
  ) {
    updateUser(
      input: {
        id: $id
        username: $username
        lastName: $lastName
        email: $email
        role: $role
        firstName: $firstName
      }
    ) {
      id
      firstName
      lastName
      email
      role
      username
    }
  }
`
export const CHANGE_USER_PASSWORD_MUTATION = gql`
  mutation ChangeUserPassword($id: Int!, $newPassword: String!) {
    changeUserPassword(id: $id, newPassword: $newPassword) {
      id
      firstName
      lastName
      email
      role
      username
    }
  }
`
