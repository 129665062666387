import gql from 'graphql-tag'

export const GET_FLASH_MESSAGE = gql`
  query GetNofication {
    flashMessage {
      open
      message
      variant
    }
  }
`
