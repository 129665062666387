import React from 'react'
import PropTypes from 'prop-types'
import { withFormik, Form } from 'formik'
import * as Yup from 'yup'
import TextField from '@material-ui/core/TextField'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import MenuItem from '@material-ui/core/MenuItem'
import { Query } from '@apollo/react-components'
import { LIST_ITEM_QUERY } from '../../../queries'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { addItemFormStyles } from '../../../assets/styles'

function renderInputComponent(inputProps) {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node)
          inputRef(node)
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.description, query)
  const parts = parse(suggestion.description, matches)

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => (
          <span key={index} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
    width: '230px',
    marginRight: '20px',
    display: 'inline-block',
    [theme.breakpoints.down('xs')]: {
      width: '250px',
    },
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing(2),
  },
}))

function CreateForm(props) {
  let {
    errors,
    handleBlur,
    touched,
    values,
    handleChange,
    setFieldValue,
  } = props

  const classes = useStyles()

  const [search, setSearch] = React.useState('')

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSearch(value)
  }

  const getSuggestionValue = suggestion => {
    values.id = suggestion.id
    values.data = suggestion
    values.search = suggestion.description
    return suggestion.description
  }

  const handleSuggestionsClearRequested = () => {}
  const autosuggestProps = {
    renderInputComponent,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    getSuggestionValue,
    renderSuggestion,
  }

  const classes1 = addItemFormStyles()

  return (
    <Container>
      <Form>
        <Query
          query={LIST_ITEM_QUERY}
          variables={{ number: 1, size: 20, filter: search }}
        >
          {({ data: { listItems } }) => {
            return (
              <Autosuggest
                suggestions={listItems ? listItems.data : []}
                {...autosuggestProps}
                inputProps={{
                  classes,
                  error: errors.search && touched.search,
                  helperText: touched.search && errors.search,
                  id: 'react-autosuggest-simple',
                  label: 'Items',
                  placeholder: 'Search Items',
                  name: 'search',
                  value: values.search,
                  onChange: (e, { newValue }) => {
                    setFieldValue('search', newValue)
                  },
                }}
                theme={{
                  container: classes.container,
                  suggestionsContainerOpen: classes.suggestionsContainerOpen,
                  suggestionsList: classes.suggestionsList,
                  suggestion: classes.suggestion,
                }}
                renderSuggestionsContainer={options => (
                  <Paper {...options.containerProps}>{options.children}</Paper>
                )}
              />
            )
          }}
        </Query>

        <TextField
          placeholder="Input Quantity"
          name="quantity"
          label="Quantity"
          onBlur={handleBlur}
          className={classes1.descriptionField}
          error={errors.quantity && touched.quantity}
          helperText={touched.quantity && errors.quantity}
          type="number"
          value={values.quantity}
          onChange={handleChange}
        />
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          style={{ marginBottom: '20px', color: '#2d3e50', marginTop: '10px' }}
          type="submit"
        >
          Add
        </Button>
      </Form>
    </Container>
  )
}
CreateForm.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}
const AddItemWithFormik = withFormik({
  enableReinitialize: true,
  mapPropsToValues: ({ quantity }) => {
    return {
      quantity: quantity || '',
      search: '',
    }
  },
  validationSchema: Yup.object().shape({
    quantity: Yup.number()
      .positive()
      .integer()
      .required('Quantity is required'),
    search: Yup.string().required('Please Enter Item'),
  }),
  handleSubmit: ({ id, data, quantity }, { props, resetForm }) => {
    props.setValue({ id, data, quantity })
    resetForm()
  },
})(CreateForm)

export default AddItemWithFormik
