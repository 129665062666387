import React from 'react'
import PropTypes from 'prop-types'
import { TextField, MenuItem, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingRight: theme.spacing(1),
  },
  textField: {
    width: '100%',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  menu: {
    width: '100%',
  },
}))

export default function CategoryFilter({ category, setCategory }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <TextField
        id="outlined-select-currency"
        select
        label="Select"
        className={classes.textField}
        value={category}
        onChange={e => setCategory(e.target.value)}
        SelectProps={{
          MenuProps: {
            className: classes.menu,
          },
        }}
        helperText="Select category"
        margin="normal"
        variant="outlined"
      >
        <MenuItem value="">NO FILTER</MenuItem>
        <MenuItem value="TRANSFERRED">TRANSFERRED</MenuItem>
        <MenuItem value="WRITTEN_OFF">WRITTEN_OFF</MenuItem>
      </TextField>
    </div>
  )
}

CategoryFilter.propTypes = {
  category: PropTypes.string.isRequired,
  setCategory: PropTypes.func.isRequired,
}
