import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ThemeProvider } from '@material-ui/styles'
import { customCalenderTheme } from '../../assets/styles'
import { Typography } from '@material-ui/core'

function YearMonthPicker({ selectedDate, handleDateChange, isMobile }) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {!isMobile && (
        <Typography
          style={{ padding: '5px', float: 'left', lineHeight: '3.5' }}
        >
          DATE:
        </Typography>
      )}
      <ThemeProvider theme={customCalenderTheme}>
        <Fragment>
          {!isMobile && (
            <DatePicker
              autoOk
              views={['year', 'month']}
              disableFuture
              value={selectedDate}
              onChange={handleDateChange}
              style={{ margin: '16px' }}
            />
          )}
          {isMobile && (
            <KeyboardDatePicker
              autoOk
              views={['year', 'month']}
              disableFuture
              value={selectedDate}
              onChange={handleDateChange}
              style={{
                marginLeft: '15%',
                marginBottom: '16px',
                marginRight: '50px',
                marginTop: '22px',
              }}
              InputAdornmentProps={{ position: 'start' }}
            ></KeyboardDatePicker>
          )}
        </Fragment>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

YearMonthPicker.propTypes = {
  selectedDate: PropTypes.any,
  handleDateChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
}

export default YearMonthPicker
