import React, { Fragment, useState, useEffect } from 'react'
import { TextField, Container, Button, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { expensesFormStyle } from './ExpensesFormStyle'

export default function ExpensesForm({
  state,
  editValues,
  expenses,
  setState,
  mutate,
  date,
  isEdit,
  setIsEdit,
}) {
  let [values, setValues] = React.useState({
    description: '',
    amount: 0,
    accountingPeriod: new Date(),
  })

  let [desc_error, setDescError] = useState(false)
  let [amount_error, setAmountError] = useState(false)
  let [ButtonTitle, setButtonTitle] = useState('Add Expenses')

  const classes = expensesFormStyle()

  useEffect(() => {
    if (isEdit) {
      setValues(editValues)
      setButtonTitle('Update')
      setIsEdit(false)
    }
  }, [isEdit, editValues, setIsEdit])

  if (values.accountingPeriod !== date) {
    setValues({ ...values, accountingPeriod: date })
  }

  const handleSubmit = () => {
    if (values.description === '') {
      setDescError(true)
    } else {
      setDescError(false)
    }

    if (values.amount === 0) {
      setAmountError(true)
    } else {
      setAmountError(false)
    }

    if (values.description !== '' && values.amount !== 0 && !editValues) {
      let data = expenses
      data.push(values)
      setState({ ...state, expenses: data })
      mutate({ variables: values })
      setValues({
        ...values,
        description: '',
        amount: 0,
        accountingPeriod: new Date(),
      })
    }

    if (editValues) {
      mutate({ variables: values })
    }
  }

  return (
    <Fragment>
      <Container maxWidth="xl" style={{ padding: '2px', display: 'block' }}>
        <Grid container spacing={1}>
          <Grid item sm={12} md={6}>
            <TextField
              fullWidth
              name="description"
              style={{ margin: '5px', padding: '0px' }}
              label="Enter Description"
              value={values.description}
              error={desc_error}
              onChange={e =>
                setValues({ ...values, description: e.target.value })
              }
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <TextField
              fullWidth
              type="number"
              name="amount"
              value={values.amount}
              error={amount_error}
              onChange={e =>
                setValues({ ...values, amount: parseFloat(e.target.value) })
              }
              style={{ margin: '5px', padding: '0px' }}
              label="Enter amount"
            />
          </Grid>

          <Grid item sm={12} md={12} style={{ padding: '0px' }}>
            <Button
              className={classes.expensesButton}
              fullWidth
              variant="outlined"
              color="primary"
              onClick={() => handleSubmit()}
            >
              {ButtonTitle}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  )
}

ExpensesForm.propTypes = {
  setState: PropTypes.func,
  expenses: PropTypes.array,
  setIsEdit: PropTypes.func,
  isEdit: PropTypes.bool,
  editValues: PropTypes.object,
}
