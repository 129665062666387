import { makeStyles } from '@material-ui/core/styles'

export const expensesFormStyle = makeStyles(theme => ({
  expensesButton: {
    margin: '5px',
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginRight: '0px',
      marginLeft: '5px',
      marginTop: '0px',
      marginBottom: '0px',
      padding: '0px',
      width: '99%',
      paddingRight: '10px',
    },
  },
}))
