/* eslint-disable no-undef */
import React, { Fragment, useState, useEffect } from 'react'
import { Container, Grid, Paper } from '@material-ui/core'
import WriteOffTable from './Table'
import ToolBar from '../common/ToolBar'
import { mainPageStyles } from '../assets/styles'
import Modal from '../common/Modal'
import CreateAdjustment from './Forms/CreateAdjustment'
import CategoryFilter from './CategoryFilter'
import { theme } from '../assets/styles'
import { ThemeProvider } from '@material-ui/styles'
import Fab from '../common/Fab'
import AddIcon from '@material-ui/icons/Add'

export default function InventoryAdjustment() {
  const classes = mainPageStyles()

  const [searchString, setSearch] = useState('')
  const [category, setCategory] = useState('')
  const [selectedDate, handleDateChange] = useState(new Date())
  const [open, setOpen] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => resize())

  window.addEventListener('resize', resize)

  function resize() {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl" className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ToolBar
                title="Inventory Adjustment"
                selectedDate={selectedDate}
                handleDateChange={handleDateChange}
                setOpen={setOpen}
                isMobile={isMobile}
                setSearch={setSearch}
              ></ToolBar>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <CategoryFilter
                category={category}
                setCategory={setCategory}
              ></CategoryFilter>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper style={{ display: 'block' }}>
                <WriteOffTable
                  searchString={searchString}
                  category={category}
                  date={selectedDate}
                ></WriteOffTable>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        {isMobile && (
          <Fab
            onClick={() => setOpen(true)}
            color="primary"
            icon={<AddIcon></AddIcon>}
          ></Fab>
        )}
        <Modal
          open={open}
          handleClose={() => setOpen(false)}
          title={'New adjustment'}
          FormContent={<CreateAdjustment setOpen={setOpen}></CreateAdjustment>}
        ></Modal>
      </ThemeProvider>
    </Fragment>
  )
}
