import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  withStyles,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core'
import { InvoiceName } from '../../assets/styles'
import YearMonthPicker from './Datepicker'

import PropTypes from 'prop-types'
import CreateForm from './CreateForm'
import CreateTable from './CreateTable'
import _ from 'lodash'
import { ShowFlashMessage } from 'utils'
import { CREATE_SALE_INVOICE_MUTATION } from '../../../../mutation'
import { Mutation } from '@apollo/react-components'

const styles = {
  dialogPaper: {
    minHeight: '60vh',
    maxHeight: '60vh',
    minWidth: '80vh',
    maxWidth: '80vh',
  },
}
const CreateDialog = ({ open, handleClose }) => {
  const [items, setItems] = React.useState([])
  let [customerName, setCustomerName] = React.useState('')
  let [selectedDate, handleDateChange] = React.useState(new Date())
  const deleteItem = id => {
    let index = _.findIndex(items, { id: id })
    items.splice(index, 1)
    setItems(items.concat())
  }
  const handleAddItem = value => {
    let itemExist = _.find(items, data => {
      return data.id === value.id
    })

    if (itemExist) {
      let index = _.findIndex(items, { id: value.id })
      itemExist.quantity += value.quantity
      items.splice(index, 1)
      value = itemExist
    }
    setItems(items.concat(value))
  }

  const handleResetItem = () => {
    setItems([])
    handleClose()
  }
  let userData = JSON.parse(localStorage.getItem('user'))
  let enableDate = userData.role.includes('ADMIN')

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleResetItem}
        aria-labelledby="alert-dialog-Create"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-Create"
          style={{ backgroundColor: '#2d3e50', color: '#FFFFFF' }}
        >
          {'Create Sales Invoice'}
        </DialogTitle>
        <DialogContent>
          <div>
            <CreateForm setValue={handleAddItem} />
            <CreateTable items={items} deleteItem={deleteItem} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <InvoiceName
                  placeholder="Name"
                  name="soldTo"
                  id="standard-with-placeholder"
                  label="Name"
                  value={customerName}
                  onChange={e => setCustomerName(e.target.value)}
                />
              </Grid>
              {enableDate && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <YearMonthPicker
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                  />
                </Grid>
              )}
            </Grid>

            <Mutation
              mutation={CREATE_SALE_INVOICE_MUTATION}
              refetchQueries={['salesInvoice']}
              update={cache => {
                ShowFlashMessage(cache, 'Invoice Succesfully Created')
                handleResetItem()
              }}
            >
              {mutate => {
                return (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} />
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Button
                        onClick={() => {
                          let newItems = items.map(item => {
                            return {
                              id: item.id,
                              quantity: item.quantity,
                              discount: item.discount,
                            }
                          })
                          mutate({
                            variables: {
                              items: newItems,
                              date: selectedDate,
                            },
                          })
                        }}
                        color="primary"
                        className="btn btn-primary"
                        variant="contained"
                        fullWidth
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Grid>{' '}
                  </Grid>
                )
              }}
            </Mutation>
          </div>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </div>
  )
}

CreateDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  selectedDate: PropTypes.instanceOf(Date),
  handleDateChange: PropTypes.func,
}

export default withStyles(styles)(CreateDialog)
