/* eslint-disable no-undef */
import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Container,
} from '@material-ui/core'
import { materialMasterTableStyle } from '../../../../assets/styles'

export default function ItemsTable({ items }) {
  const classes = materialMasterTableStyle()

  let total = 0
  return (
    <Container style={{ paddingTop: '10px', paddingBottom: '50px' }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} align="left">
              Item Code
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Description
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Material Type
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Quantity
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Unit Cost
            </TableCell>
            <TableCell className={classes.tableCell} align="left">
              Cost
            </TableCell>
          </TableRow>
        </TableHead>
        {items && (
          <TableBody>
            {items.map(row => {
              total += parseInt(row.cost)
              return (
                <TableRow hover key={row.id}>
                  <TableCell align="left">{row.item.itemCode}</TableCell>
                  <TableCell align="left">{row.item.description}</TableCell>
                  <TableCell align="left">{row.item.materialType}</TableCell>
                  <TableCell align="left">{row.quantity}</TableCell>
                  <TableCell align="left">{row.item.unitCost}</TableCell>
                  <TableCell align="left">{row.cost}</TableCell>
                </TableRow>
              )
            })}
            {items.length !== 0 && (
              <TableRow>
                <TableCell>Total Amount</TableCell>
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right" />
                <TableCell align="right">{total}</TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </Container>
  )
}

ItemsTable.propTypes = {
  items: PropTypes.array,
}
