import React, { Fragment } from 'react'
import Avatar from '@material-ui/icons/AccountCircle'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import PasswordDialog from './ChangePassword/PasswordDialog'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import { withFormik, Form } from 'formik'

function MadeWithLove() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Built with love by Chan '}
    </Typography>
  )
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 1),
  },
}))

function ProfileForm(props) {
  const classes = useStyles()
  let [dialogOpen, setDialogOpen] = React.useState(false)
  let { errors, handleBlur, touched, values, handleChange } = props

  return (
    <Fragment>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          {values.role &&
            values.role.map((role, index) => {
              return (
                <Typography key={index} component="h1" variant="h5">
                  {role}
                </Typography>
              )
            })}

          <Form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={values.firstName}
                  onChange={handleChange}
                  error={errors.firstName && touched.firstName}
                  helperText={touched.firstName && errors.firstName}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={values.lastName}
                  onChange={handleChange}
                  error={errors.lastName && touched.lastName}
                  helperText={touched.lastName && errors.lastName}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email && touched.email}
                  helperText={touched.email && errors.email}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="username"
                  label="Username"
                  id="username"
                  autoComplete="username"
                  value={values.username}
                  onChange={handleChange}
                  error={errors.username && touched.username}
                  helperText={touched.username && errors.username}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => setDialogOpen(true)}
                >
                  CHANGE PASSWORD ?
                </Button>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Update Profile
                </Button>
              </Grid>
            </Grid>
          </Form>
          <PasswordDialog
            open={dialogOpen}
            handleClose={() => setDialogOpen(false)}
          />
        </div>
        <Box mt={5}>
          <MadeWithLove />
        </Box>
      </Container>
    </Fragment>
  )
}

ProfileForm.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}
const Formik = withFormik({
  enableReinitialize: true,
  mapPropsToValues({ role, id, firstName, lastName, email, username }) {
    return {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      username: username || '',
      id: id,
      role: role,
    }
  },
  validationSchema: Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().required('Email is required'),
    username: Yup.string().required('Username is required'),
  }),
  handleSubmit: (values, { props }) => {
    props.onSubmit({ variables: values })
  },
})(ProfileForm)

export default Formik
