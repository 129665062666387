import React from 'react'
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import PropTypes from 'prop-types'
import ItemsTable from '../ShowItemTable/ItemsTable'
const ItemsDialog = ({ open, handleClose, invoice }) => {
  //   let [items, setItems] = React.useState([])
  //   const handleAddItem = value => {
  //     setItems(items.concat(value))
  //   }
  const handleResetItem = () => {
    // setItems([])
    handleClose()
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleResetItem}
        aria-labelledby="alert-dialog-Create"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-Create">{'Items'}</DialogTitle>
        <DialogContent>
          <div>{invoice && <ItemsTable invoice={invoice} />}</div>
        </DialogContent>
      </Dialog>
    </div>
  )
}

ItemsDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  invoice: PropTypes.array.isRequired,
}

export default ItemsDialog
