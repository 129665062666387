import styled from 'styled-components'
import { Fab, Button, TextField, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { createMuiTheme } from '@material-ui/core/styles'

export const SalesInvoiceToolbarStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  addButton: {
    paddingTop: '10px',
    paddingBottom: '10px',
    cursor: 'pointer',
  },
  toolbar: {
    width: '100%',
    marginBottom: '20px',
    padding: '20px',
    display: 'inline-block',
  },
  searchInput: {
    float: 'left',
  },
}))

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#2d3e50',
    },
  },
})

export const addItemBtn = makeStyles(() => ({
  addItemBtn: {
    color: '#FFFFFF',
    borderColor: '#FFFFFF',
    '&:hover': {
      color: '#2d3e50!important',
      backgroundColor: '#FFFFFF!important',
      borderColor: '#2d3e50!important',
    },
  },
}))

export const CreateFab = styled(Fab)`
  position: absolute !important;

  right: 35px !important;
  top: 92px !important;
  z-index: 10000;
`

export const AddButton = styled(Button)`
  margin-left: -20% !important;
`

export const CenterWrapper = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10%;
`

export const ItemField = styled(TextField)``
export const QuantityField = styled(TextField)`
  width: 60px;
`
export const DiscountField = styled(TextField)`
  width: 60px;
`

export const PriceField = styled(TextField)`
  width: 15%;
  margin-left: 1% !important;
`
export const SoldByTextField = styled(TextField)`
  margin-left: 5% !important;
`
export const ItemFieldSearch = styled(TextField)``

export const PriceSpacing = styled.div`
  margin-left: 55%;
  position: absolute;
`

export const PaperSize = styled(Paper)`
margin-top: -8% !important
  width: 40% !important;
  position: absolute !important;
`
export const SubmitButton = styled(Button)``
export const InvoiceName = styled(TextField)``
export const DialogSpacing = styled.div`
  margin-left: 55%;
  position: absolute;
`
export const customCalenderTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        color: '#FFFFFF',
        backgroundColor: '#2d3e50',
      },
    },
    MuiInputBase: {
      input: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
    },

    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
      toolbarBtnSelected: {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
      },
    },
    MuiPickersYear: {
      yearSelected: {
        color: '#2d3e50',
      },
    },
    MuiPickersMonth: {
      monthSelected: {
        color: '#2d3e50',
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: '#2d3e50',
      },
    },
  },
})
