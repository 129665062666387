import gql from 'graphql-tag'

export const LIST_INVENTORY_ADJUSTMENT_QUERY = gql`
  query ListInventoryAdjustment(
    $page: Page
    $category: INVENTORY_ADJUSTMENT_CATEGORY
  ) {
    listInventoryAdjustment(page: $page, category: $category) {
      pageInfo {
        total
        page
        perPage
        lastPage
      }
      data {
        refCode
        category
        reason
        created_at
        items {
          refCode
          item {
            id
            itemCode
            unitOfMeasure
            materialType
            unitCost
            sellingPrice
            description
          }
          quantity
          created_at
        }
      }
    }
  }
`
