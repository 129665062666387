import React from 'react'
import { Query } from '@apollo/react-components'
import { DISPLAY_ACCOUNY_QUERY } from './query'
import { CircularProgress } from '@material-ui/core'
import { Mutation } from '@apollo/react-components'
import { UPDATE_ACCOUNT_MUTATION } from './mutation'
import { ShowFlashMessage } from 'utils'
import Grid from '@material-ui/core/Grid'
import ProfileForm from './ProfileForm'
const Profile = () => {
  return (
    <Grid container spacing={1}>
      <Query query={DISPLAY_ACCOUNY_QUERY} fetchPolicy={'cache-and-network'}>
        {({ data, loading }) => {
          if (loading || !data) return <CircularProgress />
          let { me: userInfo } = data
          return (
            <Mutation
              mutation={UPDATE_ACCOUNT_MUTATION}
              update={cache => {
                ShowFlashMessage(cache, `Account Successfuly Upadated`)
              }}
              onCompleted={() => {}}
            >
              {mutate => {
                return <ProfileForm onSubmit={mutate} {...userInfo} />
              }}
            </Mutation>
          )
        }}
      </Query>
    </Grid>
  )
}

export default Profile
