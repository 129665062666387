import React, { useState } from 'react'
import { Container, Grid, Paper } from '@material-ui/core'
import { ReportsPageStyles } from './assets/styles'
import ToolBar from './components/CustomToolBar/CustomToolBar'
import ProfitAndLossStatement from './components/ProfitAndLoss/ProfitAndLossStatement'
import ReportsList from './components/ReportsList'
import Expenses from './components/Expenses'
import PropTypes from 'prop-types'
import { Router } from '@reach/router'

export default function ReportsPage(props) {
  let [state, setState] = useState({
    date: new Date(),
  })

  const classes = ReportsPageStyles()

  return (
    <React.Fragment>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <ToolBar
              title={'Reports'}
              selectedDate={state.date}
              handleDateChange={setState}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <Paper>
              <Router>
                <ProfitAndLossStatement
                  path="/profit-and-loss/"
                  date={state.date}
                />

                <Expenses path="/expenses/" date={state.date} />
                <ReportsList
                  path="/reports-list/"
                  date={state.date}
                  handleDateChange={setState}
                />
              </Router>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  )
}

ReportsPage.propTypes = {
  profitandloss: PropTypes.bool,
  reportslist: PropTypes.bool,
  expenses: PropTypes.bool,
}
