import gql from 'graphql-tag'

export const CREATE_PURCHASE_ORDER_MUTATION = gql`
  mutation PurchaseOrder(
    $purchaseOrderNum: String
    $items: [PurchaseOrderItemInput!]!
  ) {
    placePurchaseOrder(
      input: { purchaseOrderNum: $purchaseOrderNum, items: $items }
    ) {
      id
      requestedBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      items {
        id
        item {
          id
          itemCode
          unitCost
          unitOfMeasure
          materialType
          sellingPrice
          description
        }
        quantity
        cost
      }
    }
  }
`

export const APPROVE_ORDER_MUTATION = gql`
  mutation PurchaseOrder($orderId: Int!) {
    approveOrder(orderId: $orderId) {
      id
      purchaseOrderNum
      requestedBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      approvedBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      status
      receivedBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      created_at
      updated_at
      items {
        id
        item {
          id
          itemCode
          unitOfMeasure
          unitCost
          materialType
          sellingPrice
          description
        }
        quantity
        cost
        received
      }
    }
  }
`

export const RECEIVE_ORDER_MUTATION = gql`
  mutation PurchaseOrder($orderId: Int!) {
    receiveOrder(orderId: $orderId) {
      id
      purchaseOrderNum
      requestedBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      approvedBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      status
      receivedBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      created_at
      updated_at
      items {
        id
        item {
          id
          itemCode
          unitOfMeasure
          unitCost
          materialType
          sellingPrice
          description
        }
        quantity
        cost
        received
      }
    }
  }
`

export const REJECT_ORDER_MUTATION = gql`
  mutation PurchaseOrder($orderId: Int!) {
    rejectOrder(orderId: $orderId) {
      id
      purchaseOrderNum
      requestedBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      approvedBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      status
      receivedBy {
        id
        firstName
        lastName
        email
        role
        username
      }
      created_at
      updated_at
      items {
        id
        item {
          id
          itemCode
          unitOfMeasure
          unitCost
          materialType
          sellingPrice
          description
        }
        quantity
        cost
        received
      }
    }
  }
`
