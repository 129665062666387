import React from 'react'
import { SalesInvoiceToolbarStyles } from './assets/styles'
import Table from './Table/table'
import Toolbar from './Table/toolbar'
import { Container, Grid } from '@material-ui/core'

export default function SalesInvoice() {
  const classes = SalesInvoiceToolbarStyles()

  let [selectedDate, handleDateChange] = React.useState(new Date())
  let [searchString, setSearch] = React.useState('')
  let [dialog, setDialog] = React.useState({
    open: false,
    type: null,
    data: null,
  })
  return (
    <div>
      <Container maxWidth="xl" className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Toolbar
              selectedDate={selectedDate}
              handleDateChange={handleDateChange}
              setSearch={setSearch}
              setDialog={setDialog}
              type={dialog.type}
              title="Invoice"
            />
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Table
              type={dialog.type}
              date={selectedDate}
              search={searchString}
              setDialog={setDialog}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}
