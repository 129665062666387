import React, { Fragment } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
} from '@material-ui/core'
import { LIST_PURCHASE_ORDER_QUERY } from '../../queries'
import { materialMasterTableStyle } from '../../assets/styles'
import { Query } from '@apollo/react-components'
import TablePaginationActions from './TablePaginationActions'
import CreateModal from '../../assets/customModal'
import ItemsTable from './Actions/ShowPurchaseOrderItem/Table'
import { ActionsMenu } from './Actions/ActionButtons'
import PropTypes from 'prop-types'

export default function OrderTable({ date, search }) {
  const classes = materialMasterTableStyle()
  const [state, setState] = React.useState({
    open: false,
    page: 0,
    limit: 5,
    itemId: null,
  })

  // Pagination Functions
  const handleChangePage = (event, page) => {
    setState({ ...state, page: page })
  }

  const handleChangeRowsPerPage = event => {
    setState({ ...state, limit: parseInt(event.target.value) })
  }
  const [dialog, setOpenDialog] = React.useState({
    inDialog: false,
    outDialog: false,
    items: null,
  })

  let userData = JSON.parse(localStorage.getItem('user'))

  let showActions = (userData, id, status) => {
    if (userData.role !== null && userData.role.indexOf('ADMIN') !== -1) {
      if (status !== null && status === 'pending') {
        return <ActionsMenu id={id} status="pending" />
      } else if (status != null && status === 'processing') {
        return <ActionsMenu id={id} status="processing" />
      } else if (status != null && status === 'completed') {
        return <ActionsMenu status="completed" />
      } else if (status != null && status === 'rejected') {
        return <ActionsMenu status="rejected" />
      }
    } else if (userData !== null && userData.role.indexOf('PURCHASER') !== -1) {
      if (status !== null && status === 'processing') {
        return <ActionsMenu id={id} status="processing" />
      } else if (status != null && status === 'completed') {
        return <ActionsMenu status="completed" />
      } else if (status != null && status === 'rejected') {
        return <ActionsMenu status="rejected" />
      }
    } else {
      return <ActionsMenu status="completed" />
    }
  }

  const colorStatus = {
    pending: 'red',
    completed: 'green',
    rejected: 'red',
    processing: 'orange',
  }

  return (
    <Fragment>
      <Paper style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <Query
          query={LIST_PURCHASE_ORDER_QUERY}
          variables={{
            number: state.page + 1,
            limit: state.limit,
            date: date,
            filter: search,
          }}
        >
          {({ error, loading, data: { listPurchaseOrders } }) => {
            if (error) return 'error'
            if (loading || !listPurchaseOrders) return null
            let {
              data: purchaseOrderItems,
              pageInfo: { page, perPage, total },
            } = listPurchaseOrders

            return (
              <Fragment>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left">
                        PO Number
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Created At
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Updated At
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Requested By
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Received By
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Status
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Approved By
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {purchaseOrderItems.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={10}
                          style={{
                            textAlign: 'center',
                            padding: '20px',
                          }}
                        >
                          No results found.
                        </TableCell>
                      </TableRow>
                    )}
                    {purchaseOrderItems.map(row => (
                      <TableRow hover key={row.id}>
                        <TableCell align="left">
                          <Button
                            style={{
                              color: colorStatus[row.status],
                              width: '120px',
                            }}
                            onClick={() =>
                              setOpenDialog({
                                inDialog: true,
                                items: row.items,
                              })
                            }
                          >
                            {row.purchaseOrderNum}
                          </Button>
                        </TableCell>
                        <TableCell align="left">{row.created_at}</TableCell>
                        <TableCell align="left">{row.updated_at}</TableCell>
                        <TableCell align="left">
                          {row.requestedBy.firstName}
                        </TableCell>
                        <TableCell align="left">
                          {row.receivedBy.firstName}
                        </TableCell>
                        <TableCell align="left">
                          <div>
                            <React.Fragment>
                              <Button
                                style={{
                                  color: colorStatus[row.status],
                                  width: '120px',
                                }}
                              >
                                {row.status}
                              </Button>
                            </React.Fragment>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          {row.approvedBy.firstName}
                        </TableCell>
                        <TableCell align="left">
                          {showActions(userData, row.id, row.status)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <TablePagination
                  align="right"
                  style={{
                    alignContent: 'right',
                    alignItems: 'right',
                    alignSelf: 'right',
                    textAlign: 'right',
                  }}
                  rowsPerPageOptions={[5, 10, 20]}
                  count={parseInt(total)}
                  rowsPerPage={parseInt(perPage)}
                  page={page - 1}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                  component={'div'}
                />
              </Fragment>
            )
          }}
        </Query>
      </Paper>
      {/* Items Table */}
      <CreateModal
        open={dialog.inDialog}
        title="Items Table"
        closeDialog={() => setOpenDialog({ inDialog: false })}
        FormContent={<ItemsTable items={dialog.items} />}
      />
    </Fragment>
  )
}

OrderTable.propTypes = {
  search: PropTypes.string,
  date: PropTypes.instanceOf(Date),
}
