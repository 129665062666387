import React from 'react'
import { Router } from '@reach/router'
import AppSnackbar from './components/common/Snackbar'
import Login from 'components/Login'
import Dashboard from 'components/Dashboard/layout/components/Dashboard'
import Home from 'components/Dashboard/components/Home'
import MaterialMaster from 'components/Dashboard/components/MaterialMaster'
import SalesInvoice from 'components/Dashboard/components/SalesInvoice'
import PurchaseOrder from 'components/Dashboard/components/PurchaseOrder/'
import Reports from 'components/Dashboard/components/Reports'
import Accounts from 'components/Dashboard/components/Accounts'
import Profile from 'components/Dashboard/layout/components/Dashboard/Menu/component/Profile'
import Sales from 'components/Dashboard/components/SalesInvoice/components/Sales/index'
import InventoryAdjustment from 'components/Dashboard/components/InventoryAdjustment'
import { ApolloProvider } from '@apollo/react-hooks'
import client from './client'
function App() {
  return (
    <ApolloProvider client={client}>
      <div className="App">
        <Router>
          <Login path="/login" />
          <Dashboard path="/">
            <Home path="/" />
            <MaterialMaster path="/material-master" />
            <SalesInvoice path="/sales-invoice" />
            <PurchaseOrder path="/purchase-order" />
            <Reports path="/reports/*" />
            <InventoryAdjustment path="/inv-adjustment"></InventoryAdjustment>
            <Accounts path="accounts" />
            <Profile path="/profile" />
            <Sales path="/sales" />
          </Dashboard>
        </Router>
        <AppSnackbar />
      </div>
    </ApolloProvider>
  )
}

export default App
