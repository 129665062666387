import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core'
import { Query } from '@apollo/react-components'
import { LIST_REPORTS_QUERY } from '../../query/query'
import TableFooterReport from './TableFooterReport'
import Currency from 'react-currency-formatter'
import moment from 'moment-timezone'
import { navigate } from '@reach/router'
import Loading from '../../common/Loading'
import { ReportListStyle } from './styles'

export default function ReportsList({ handleDateChange }) {
  const classes = ReportListStyle()

  let [state, setState] = useState({
    page: 1,
    limit: 10,
  })

  // Pagination Functions
  const handleChangePage = (event, page) => {
    setState({ ...state, page: page })
  }

  const handleChangeRowsPerPage = event => {
    setState({ ...state, limit: parseInt(event.target.value) })
  }

  const handleClickReport = createdAt => {
    handleDateChange({ date: new Date(createdAt) })
    navigate(`/reports/profit-and-loss`)
  }

  return (
    <Fragment>
      <Query
        query={LIST_REPORTS_QUERY}
        variables={{ number: state.page, limit: state.limit }}
      >
        {({ loading, error, data: { listPlReports } }) => {
          if (error) return 'error'
          if (loading) return <Loading />

          let {
            data: reports,
            pageInfo: { page, perPage, total },
          } = listPlReports

          return (
            <>
              <div className={classes.tableStyle}>
                <Table className={classes.tableStyle}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Report ID</TableCell>
                      <TableCell align="center">Net Profit</TableCell>
                      <TableCell align="center">Revenue</TableCell>
                      <TableCell align="center"> Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading && (
                      <TableRow>
                        <TableCell align="center" colSpan={4}>
                          Loading data...
                        </TableCell>
                      </TableRow>
                    )}
                    {reports.length === 0 && (
                      <TableRow>
                        <TableCell
                          align="center"
                          colSpan={4}
                          style={{ padding: '50px' }}
                        >
                          No Data
                        </TableCell>
                      </TableRow>
                    )}
                    {reports.map((report, key) => {
                      return (
                        <TableRow
                          hover
                          key={key}
                          onClick={() => handleClickReport(report.created_at)}
                        >
                          <TableCell align="left">{report.plNum}</TableCell>
                          <TableCell align="right">
                            <Currency
                              quantity={report.netProfit}
                              currency="PHP"
                            />
                          </TableCell>
                          <TableCell align="right">
                            <Currency
                              quantity={report.revenue}
                              currency="PHP"
                            />
                          </TableCell>
                          <TableCell align="center">
                            {moment(report.created_at).format('MMMM DD, YYYY')}
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
                <TableFooterReport
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  total={parseInt(total)}
                  perPage={parseInt(perPage)}
                  page={parseInt(page)}
                />
              </div>
            </>
          )
        }}
      </Query>
    </Fragment>
  )
}

ReportsList.propTypes = {
  handleDateChange: PropTypes.func.isRequired,
}
