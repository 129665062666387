import gql from 'graphql-tag'

export const CREATE_ITEM_MUTATION = gql`
  mutation CreateItem(
    $id: Int
    $description: String!
    $unitOfMeasure: UM!
    $materialType: MT!
    $unitCost: Float!
    $sellingPrice: Float!
  ) {
    item(
      input: {
        id: $id
        description: $description
        unitOfMeasure: $unitOfMeasure
        materialType: $materialType
        unitCost: $unitCost
        sellingPrice: $sellingPrice
      }
    ) {
      id
      itemCode
      description
      unitOfMeasure
      materialType
      unitCost
      sellingPrice
    }
  }
`
